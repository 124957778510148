/* eslint-disable max-len */
import React, { useState } from 'react';
import girl from 'assets/images-v0.1.2/girl.webp';
import close from 'assets/images-v0.1.2/xmark.svg';
import plus from 'assets/images-v0.1.2/plus.svg';
import './Questions.css';

const Question = ({ question, answer, expand }) => {
  const [open, isOpen] = useState(expand);

  const ans = answer || "Having a fast website will benefit your customer's experience with your service and product. Page speed measures the time it takes the content on a URL to load. Site speed, on the other hand, represents how your site is performing overall. I will optimize the site by – Implementing caching Minifying CSS, HTML, and Start using CDN Reduce HTTP requests";

  return (
    <div className="qus-items" onClick={() => isOpen(!open)}>
      <div className="qus-head">
        <h5>{question}</h5>
        {open ? <img className="pb-2 qus" src={close} alt="close" /> : <img onClick={() => isOpen(!open)} className="pb-2 qus" src={plus} alt="open" />}
      </div>
      {open
        && (
          <p>
            {ans}
          </p>
        )}
      <div className="break" />
    </div>
  );
};

const Questions = () => {
  const questions = [
    {
      question: 'What services does your company offer?',
      answer: 'Our company, only4values, offers a range of services including website development, dashboard development, app development for iOS and Android, and custom software development. We also provide debugging and problem solving support to help our clients overcome any challenges they may face.',
      expand: true
    },
    {
      question: 'How do you work with clients to develop their ideas?',
      answer: 'We take the time to understand the specific needs and goals of our clients and work closely with them to develop customized solutions that meet their requirements. Whether you have a fully fleshed-out concept or just a spark of an idea, we have the experience and expertise to help you bring your project to market.',
      expand: true
    },
    {
      question: 'What sets your company apart in terms of quality?',
      answer: 'At only4values, we prioritize quality above all else. We only accept a limited number of clients at any given time to ensure that we can provide the highest level of service and attention to detail. Additionally, we have a proven track record of delivering successful projects for our clients, with a satisfaction rate of 90%.',
      expand: true
    },
    {
      question: 'How can I get in touch with your company to discuss my project?',
      answer: 'You can use the contact form on our website to send us a message. We\'ll do our best to get back to you as soon as possible and discuss your project in more detail.',
      expand: true
    },
  ];
  return (
    <div className="container pt-3">
      <h1 style={{ textAlign: 'center', marginBottom: '40px', color: '#3F3D56', fontWeight: '600' }}>
        <span style={{ color: '#E7AA27' }}>Got</span>
        {' '}
        any questions ?
      </h1>
      <div className="d-flex flex-column flex-lg-row gap-5">
        <div className="questions-img">
          <img src={girl} alt="" width="482px" />
        </div>
        <div className="qus-content">
          <div className="break" />
          {
            questions.map((props, key) => <Question {...props} key={key} />)
          }
        </div>
      </div>
    </div>
  );
};

export default Questions;
