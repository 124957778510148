import React from 'react';

function PopularBlogCards({ detail }) {
  const { img, title, date } = detail;
  return (
    <div className="m-4">
      <div className="shadow rounded-3  mx-auto">
        <img src={img} className="card-img-top" alt="ff" />
        <div className="card-body">
          <p className="" style={{ fontSize: '15px' }}>{date}</p>
          <h5 className="card-title pb-4">{title}</h5>
          <a href="#" className="text-black">
            Read More
          </a>
        </div>
      </div>
    </div>
  );
}

export default PopularBlogCards;
