import React from 'react';
import logo from '../../assets/images-v0.1.2/logoF.svg';
import './BrandAnimation.css';

const BrandAnimation = () => (
  <div>
    <div className="loader-bg" />
    <div className="loader">
      <img src={logo} alt="logo" />
      <div>
        <div className="line-base" />
        <div className="line" />
      </div>
    </div>
  </div>
);

export default BrandAnimation;
