import React from 'react';
import phone from '../../assets/images/phone.svg';
import location_icon from '../../assets/images/location_icon.svg';
import email from '../../assets/images/email.svg';
import './Audit.css';

function Audit() {
  return (
    <div className="py-5">
      <div className="main--box">
        <div className="contactus--info--box">
          <div className="contactus--box--one" />
          <div className="contactus--box--two" />
          <h4><u>Reqest Free Audit</u></h4>
          <div className="my-4 getintouch--sections">
            <img src={location_icon} className="fs-3 me-3" />
            <span>
              Infomation technologies building, Victoria Island, Lagos,
              Nigeria.
            </span>
          </div>
          <div className="my-4 getintouch--sections">
            <img src={phone} className="fs-3 me-3" />
            <span>+234 081-1236-4568</span>
          </div>
          <div className="my-4 getintouch--sections">
            <img src={email} className="fs-3 me-3" />
            <a className="text-white" href="mailto:info@only4values.com">
              info@only4values.com
            </a>
          </div>
        </div>
        <div className="contactus--message--box">
          <div className="contactus--white--box" />
          <div className="contactus--box--three" />
          <div className="contactus--box--four" style={{ bottom: '-55px' }} />
          <h4>Leave us a message</h4>
          <form>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Business Name"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Website URL"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Business Type"
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Location"
              />
            </div>
            <div className="mb-4">
              <select className="form-select">
                <option selected>Buget</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="d-grid gap-2">
              <button type="submit" className="text-white fw-bold btn audit--btn">
                Request Free Audit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Audit;
