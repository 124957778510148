import React from 'react';
import pathname from 'routes';
import { Link } from 'react-router-dom';

function BlogCards({ detail }) {
  const { img, title, date } = detail;
  return (
    <div className="col-6 col-lg-4">
      <div className="shadow rounded-3  mx-auto">
        <img src={img} className="card-img-top" alt="ff" />
        <div className="card-body">
          <p className="mb-2" style={{ fontSize: '15px' }}>{date}</p>
          <h5 className="pb-3 fw-bold">{title}</h5>
          <Link to={pathname.singleBlog} className="text-black fw-bold">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogCards;
