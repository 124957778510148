import React from 'react';
import './TermsConditions.css';
import { Col, Row } from 'react-bootstrap';
import terms from '../../assets/images/termsconditions.png';

function TermsConditions() {
  return (
    <div>
      <div className="container py-5 mb-5 about-section">
        <Row className="align-items-center">
          <Col md={12} lg={8}>
            <div className="about-banner">
              <h1 className="fw-bold">Terms & conditions</h1>
              <p>
                Our terms & conditions contain more specific provisions depending upon the contract size, industry, and complexity. The purpose of our terms and conditions is to provide a set of instructions for all parties to a contract.
              </p>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <img className="about-img" src={terms} alt="terms and conditions" />
          </Col>
        </Row>
      </div>
      <div className="container my-5">
        <h2 className="text-center">
          <span className="custom-color">Your Terms & Conditions </span>
          At A Glance
        </h2>
        <h4 className="text-center">you should agree with our conditions</h4>
        <div className="terms p-4 my-5">
          <div className="terms-items">
            <h3 className="g-color">1. Copyright and Usage Rights</h3>
            <p>
              Only4Values Prop. 2022. All rights reserved
              <br />
              All the content including software, video, audio, text content and
              images used on this Site belong to Only4Values. The structure of the
              site and all the materials in the website are Copyright protected.
              And thus it shall not be copied or reused in any form, or by any
              means.
            </p>
          </div>
          <div className="terms-items">
            <h3 className="g-color">2. Logo and Trademarks</h3>
            <p>
              Only4Values the name, its logo and its service names including
              (only4values.com) are trademarks, which belong to and owned by Only4Values Prop.
              These trademarks shall not be used in any
              manner without the prior written permission from Only4Values Prop.
            </p>
          </div>
          <div className="terms-items">
            <h3 className="g-color">3. Limitation of Warranties</h3>
            <p>
              We have made all possible efforts to keep the information accurate
              and up to date in this website. However, there could be chances
              that some of the contents may not represent the current state. Due
              to that, Only4Values does not warrant or shares any responsibility
              concerning to the timeliness or accuracy of the information or
              contents available in the website.
            </p>
          </div>
          <div className="terms-items">
            <h3 className="g-color">4. Limitation of Liabilities</h3>
            <p>
              In no event shall Only4Values Prop. be accountable for any direct or
              indirect damages of any kind whatsoever with respect to the
              service, contents or information as found in this website. By
              reading this agreement, you are further acknowledging and agreeing
              that Only4Values Prop., under no circumstances shall be liable for
              any such damages (including but not limited to, damages for lost
              profits, business interruption and loss of programs or
              information)
            </p>
          </div>
          <div className="terms-items">
            <h3 className="g-color">5. Laws & Jurisdictions</h3>
            <p className="pb-0">
              Articles on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
            </p>
            <p className="pb-0">
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
