import React from 'react';

function PlanCard({ data }) {
  const { planName, price } = data;
  return (
    <div className="col-12 col-md-4 col-lg-3 ">
      <div className="shadow-lg rounded-3">
        {planName === 'Platinum' ? (
          <>
            <h5 className="text-center bg-warning rounded-3 p-2">
              {planName}
            </h5>
            <p className="text-center my-2 fw-bold">{price}</p>
            <div className="px-4 py-3">
              <li className="mb-2">{data.plan1}</li>
              <li className="mb-2">{data.plan3}</li>
              <li className="mb-2">{data.plan2}</li>
              <li className="mb-2">{data.plan4}</li>
              <li className="mb-2">{data.plan5}</li>
              <li className="mb-2">{data.plan6}</li>
              <li className="mb-4">{data.plan7}</li>
            </div>
            <div className="d-flex ">
              <button
                className="w-50 mx-auto mb-3 rounded-pill border-0 bg-warning p-2 text-white fw-semibold"
                type="button"
              >
                Book Now
              </button>
            </div>
          </>
        ) : (
          <>
            <h5 className="text-center border border-warning rounded-3 p-2">
              {planName}
            </h5>
            <p className="text-center my-2 fw-bold">{price}</p>
            <div className="px-4 py-3">
              <li className="mb-2">{data.plan1}</li>
              <li className="mb-2">{data.plan3}</li>
              <li className="mb-2">{data.plan2}</li>
              <li className="mb-2">{data.plan4}</li>
              <li className="mb-2">{data.plan5}</li>
              <li className="mb-2">{data.plan6}</li>
              <li className="mb-4">{data.plan7}</li>
            </div>
            <div className="d-flex ">
              <button
                className="w-50 mx-auto mb-3 rounded-pill  border-warning bg-white p-2 text-warning fw-semibold"
                type="button"
              >
                Book Now
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PlanCard;
