import { redirect } from 'helper';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import pathname from 'routes';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import bigLogo from '../../assets/images-v0.1.2/bigLogo.svg';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            'Authorization': `Bearer ${tokenResponse.access_token}`
          }
        });
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <>
      <div className="auth-background">
        <div className="container">
          <div className="auth">
            <div className="auth-1">
              <WestOutlinedIcon onClick={() => navigate(-1)} className="back-arrow" />
              <img className="auth-img" src={bigLogo} alt="BigLogo" />
            </div>
            <div className="auth-2">
              <h2 className="text-center auth-title">Login</h2>
              <div className="auth-input">
                <input type="text" placeholder="Email Address" />
              </div>
              <div className="auth-input">
                <input type="password" placeholder="Password" />
              </div>
              <div className="check-box">
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                    <p className="remember">Remember me</p>
                  </div>
                </div>
                <div>
                  <span
                    className="forget"
                    onClick={() => redirect(navigate, pathname.yourEmail)}
                  >
                    Forget password ?
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="auth-btn"
              >
                login
              </button>
              <div className="shift-terminal d-flex">
                <p>Don't have an account? </p>
                <span className="shift ms-2 text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => redirect(navigate, pathname.signup)}>Sign Up</span>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-4">
                <div className="bg-black" style={{ height: '1px', width: '5px' }} />
                <p className="fs-5 text-secondary" style={{ margin: '0 5px 2px 5px' }}>
                  OR
                </p>
                <div className="bg-black" style={{ height: '1px', width: '5px' }} />
              </div>
              <div>
                <div onClick={() => login()} className="social-login">
                  <FcGoogle className="me-2 fs-3" />
                  <span>Log In with Google</span>
                </div>
                <div className="social-login">
                  <FaFacebook className="me-2 text-primary fs-3" />
                  <span>Log In with Facebook</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
