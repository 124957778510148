import ServiceCard from 'components/ServiceCard/ServiceCard';
import React from 'react';
import pathname from 'routes';
import './OurServices.css';
import {
  service1, service2, service3, service4, service5, service6,
  product1, product2, product3,
  market1, market2, market3,
  app1, app2, app3,
} from 'assets';

const items = [
  {
    title: 'Web Development',
    href: pathname.webServices,
    subItems: [
      { id: 1, img: service1, title: 'Business Website', },
      { id: 2, img: service2, title: 'Landing pages', },
      { id: 3, img: service3, title: 'Portfolio Website', },
      { id: 4, img: service4, title: 'E-commerce Website', },
      { id: 5, img: service5, title: 'Custom software Website', },
      { id: 6, img: service6, title: 'Blog Website', },
    ]
  },
  {
    title: 'Product Design',
    href: pathname.productService,
    subItems: [
      { id: 1, img: product1, title: 'UI/UX Design', },
      { id: 2, img: product2, title: 'Web Design', },
      { id: 3, img: product3, title: 'Graphic Design', },
    ]
  },
  {
    title: 'Digital Marketing',
    href: pathname.digitalService,
    subItems: [
      { id: 1, img: market1, title: 'SEO', },
      { id: 2, img: market2, title: 'SEM', },
      { id: 3, img: market3, title: 'SMM', },
    ]
  },
  {
    title: 'App Development',
    href: pathname.appService,
    subItems: [
      { id: 1, img: app1, title: 'Android App', },
      { id: 2, img: app2, title: 'IOS App', },
      { id: 3, img: app3, title: 'Hybrid App', },
    ]
  },

];

function OurServices() {
  return (
    <div className="service-background py-3 my-5">
      <div className="container">
        <h2 style={{ textAlign: 'center', margin: '30px 0' }}>
          Our Services
        </h2>
        {
        items.map((item, idx) => (
          <div className={idx === 0 ? '' : 'my-5'}>
            <h2 className="mb-4 align">{item.title}</h2>
            <div className="service-flex">
              <div className="service-grid">
                {
                item.subItems
                  .map((w) => (
                    <ServiceCard
                      key={w.id}
                      service={w}
                      path={item.href}
                    />
                  ))
            }
              </div>
            </div>
          </div>
        ))
      }

      </div>
    </div>
  );
}

export default OurServices;
