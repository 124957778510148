import React from 'react';
import freeaudit from 'assets/images/free_audit.svg';
import Audit from 'components/Audit/Audit';
// The css for this file is in src/pages/ContactUs/ContactUs.css

function FreeAudit() {
  return (
    <div>
      <div className="contact--upper">
        <div className="text--part">
          <h1>
            We will provide a free audit
            <br />
            report that improves your
            <br />
            website.
          </h1>
          <div className="image--for--mobile">
            <img className="img-fluid rounded-3" src={freeaudit} alt="" />
          </div>
          <p>
            This audit services begin with an overall analysis of your website to
            <br />
            assess what action is needed to best optimize it for search
            <br />
            engines.
          </p>
        </div>
        <div className="image--for--pc">
          <img className="img-fluid rounded-3" src={freeaudit} alt="" />
        </div>
      </div>
      <Audit />
    </div>
  );
}

export default FreeAudit;
