import React from 'react';
import { useNavigate } from 'react-router-dom';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import bigLogo from '../../assets/images-v0.1.2/bigLogo.svg';

const VarificationCode = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="auth-background">
        <div className="container">
          <div className="auth">
            <div className="auth-1">
              <WestOutlinedIcon onClick={() => navigate(-1)} className="back-arrow" />
              <img className="auth-img" src={bigLogo} alt="BigLogo" />
            </div>
            <div className="auth-2">
              <h2 className="text-center auth-title">varification</h2>
              <div className="auth-input">
                <input type="text" placeholder="Enter OTP Code" />
              </div>
              <div className="text-center mb-5 text-secondary">
                <p>
                  Verification code sent on your
                  {' '}
                  <br />
                  {' '}
                  provided  E-mail adress
                </p>
              </div>
              <button
                type="submit"
                className="auth-btn"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VarificationCode;
