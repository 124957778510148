import React from 'react';
import { Table } from 'react-bootstrap';
import './PlanTable.css';
import right from '../../assets/images/right.png';

function PlanTable() {
  return (
    <div className="planTable py-5 d-none d-lg-block">
      <div style={{ textAlign: 'center', marginBottom: '50px' }}>
        <h1>Compare pricing Plan</h1>
      </div>
      <div className="monfont container">
        <Table className="shadow-lg" bordered>
          <tbody>
            <tr>
              <td className="plan-bg border-bwg" rowSpan={2}>
                <h1 className="text-center pt-4 mb-5 pb-5">
                  Pick a plan &
                  <br />
                  grow your
                  {' '}
                  <br />
                  SEO traffic
                  {' '}
                  <br />
                </h1>
                <h6 className="ms-3 fw-bold">Projects</h6>
              </td>
              <td className="border-g">
                <div className="text-center p-3">
                  <h1 className="g-color fs-3 fw-bold">Starter</h1>
                  <h5 className="fw-bold fs-6">
                    $12
                    USD/
                    month
                  </h5>
                  <p>
                    loreum ipsum dolor
                    {' '}
                    <br />
                    {' '}
                    seamet aap
                  </p>
                  <button className="plan-btn">lets get started</button>
                </div>
              </td>
              <td className="border-g">
                <div className="text-center p-3">
                  <h1 className="g-color fs-3 fw-bold">Platinum</h1>
                  <h5 className="fw-bold fs-6">
                    $12
                    USD/
                    month
                  </h5>
                  <p>
                    loreum ipsum dolor
                    {' '}
                    <br />
                    {' '}
                    seamet aap
                  </p>
                  <button className="plan-btn">lets get started</button>
                </div>
              </td>
              <td className="border-g">
                <div className="text-center p-3">
                  <h1 className="g-color fs-3 fw-bold">Diamond</h1>
                  <h5 className="fw-bold fs-6">
                    $12
                    USD/
                    month
                  </h5>
                  <p>
                    loreum ipsum dolor
                    {' '}
                    <br />
                    {' '}
                    seamet aap
                  </p>
                  <button className="plan-btn">lets get started</button>
                </div>
              </td>
              <td className="border-g">
                <div className="text-center p-3">
                  <h1 className="g-color fs-3 fw-bold">Enterprice</h1>
                  <h5 className="fw-bold fs-6">
                    $12
                    USD/
                    month
                  </h5>
                  <p>
                    loreum ipsum dolor
                    {' '}
                    <br />
                    {' '}
                    seamet aap
                  </p>
                  <button className="plan-btn">lets get started</button>
                </div>
              </td>
            </tr>
            <tr>
              <td className="text-center fw-bold">1</td>
              <td className="text-center fw-bold">7</td>
              <td className="text-center fw-bold">10</td>
              <td className="text-center fw-bold border-r">15</td>
            </tr>
            <tr>
              <td className="plan-bg border-tbg">
                <h6 className="ms-3 my-2 fw-bold">
                  Chrome Extension
                  {' '}
                  <br />
                  {' '}
                  Search Limits
                </h6>
              </td>
              <td className="text-center fw-bold py-4">100</td>
              <td className="text-center fw-bold py-4">200</td>
              <td className="text-center fw-bold py-4">300</td>
              <td className="text-center fw-bold py-4 border-r">900</td>
            </tr>
            <tr>
              <td className="plan-bg border-tbg">
                <h6 className="ms-3 my-1 fw-bold">Rank Tracking</h6>
                <p className="ms-3 my-4 text-dark">
                  Tracked Keywords
                </p>
                <p className="ms-3 my-4 text-dark">
                  Tracked Keywords
                </p>
                <p className="ms-3 my-4 text-dark">Update Frequency</p>
                <p className="ms-3 my-4 text-dark">Numbers of locations</p>
                <p className="ms-3 my-4 text-dark">Data reporting</p>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">100 per project</h6>
                  <h6 className="fw-bold fs-6 mb-4">Daily</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">20</h6>
                  <img src={right} alt="right" />
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">100 per project</h6>
                  <h6 className="fw-bold fs-6 mb-4">Daily</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">20</h6>
                  <img src={right} alt="right" />
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">100 per project</h6>
                  <h6 className="fw-bold fs-6 mb-4">Daily</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">20</h6>
                  <img src={right} alt="right" />
                </div>
              </td>
              <td className="border-r">
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">100 per project</h6>
                  <h6 className="fw-bold fs-6 mb-4">Daily</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">20</h6>
                  <img src={right} alt="right" />
                </div>
              </td>
            </tr>
            <tr>
              <td className="plan-bg border-tbg">
                <h6 className="ms-3 my-1 fw-bold">Site Audit</h6>
                <p className="ms-3 my-4 text-dark">
                  Pages crawled per report
                </p>
                <p className="ms-3 my-4 text-dark">
                  Crawling Frequency
                </p>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">1,000</h6>
                  <h6 className="fw-bold fs-6 my-4">Weekly</h6>
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">3000</h6>
                  <h6 className="fw-bold fs-6 my-4">Weekly</h6>
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">5000</h6>
                  <h6 className="fw-bold fs-6 my-4">Weekly</h6>
                </div>
              </td>
              <td className="border-r">
                <div className="text-center py-5">
                  <h6 className="fw-bold fs-6 mb-4">10000</h6>
                  <h6 className="fw-bold fs-6 my-4">Weekly</h6>
                </div>
              </td>
            </tr>
            <tr>
              <td className="plan-bg border-tbg">
                <h6 className="ms-3 my-1 fw-bold">Keyword Research</h6>
                <p className="ms-3 my-4 text-dark">
                  Historical Data
                </p>
                <p className="ms-3 my-4 text-dark">
                  Keyword Suggestions
                </p>
                <p className="ms-3 my-4 text-dark">Filtering</p>
                <p className="ms-3 my-4 text-dark">Content Ideas</p>
              </td>
              <td>
                <div className="text-center py-5">
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">1,000</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">1,000</h6>
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">3000</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">3000</h6>
                </div>
              </td>
              <td>
                <div className="text-center py-5">
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">5000</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">5000</h6>
                </div>
              </td>
              <td className="border-r">
                <div className="text-center py-5">
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">10000</h6>
                  <img src={right} alt="right" />
                  <h6 className="fw-bold fs-6 my-4">10000</h6>
                </div>
              </td>
            </tr>
            <tr>
              <td className="plan-bg border-tbg border-b">
                <h6 className="ms-3 my-1 fw-bold">You can upgrade,</h6>
                <h6 className="ms-3 my-1 fw-bold">downgrade or cancel</h6>
                <h6 className="ms-3 my-1 fw-bold">your plan at any time.</h6>
              </td>
              <td className="border-b">
                <div className="text-center py-5">
                  <button className="plan-b-btn">lets get started</button>
                </div>
              </td>
              <td className="border-b">
                <div className="text-center py-5">
                  <button className="plan-b-btn">lets get started</button>
                </div>
              </td>
              <td className="border-b">
                <div className="text-center py-5">
                  <button className="plan-b-btn">lets get started</button>
                </div>
              </td>
              <td className="border-b border-r">
                <div className="text-center py-5">
                  <button className="plan-b-btn">lets get started</button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default PlanTable;
