import PlanPricingCard from 'components/PlanPricingCard/PlanPricingCard';
import React from 'react';
import { Container } from 'react-bootstrap';
import './PlanProps.css';

function PlanProps() {
  const descriptions = [
    {
      'id': 1,
      'title': 'Starter',
      'pk1': '1 Project',
      'pk2': '100 Chrome Extention Search limits',
      'pk3': '100 Tracked Keywords/Projects',
      'pk4': '1,000 page crawled/Site Audit',
      'pk5': '1,000 keyword Reserch/projects',
    },
    {
      'id': 2,
      'title': 'Platinum',
      'pk1': '7 Project',
      'pk2': '200 Chrome Extention Search limits',
      'pk3': '150 Tracked Keywords/Projects',
      'pk4': '3,000 page crawled/Site Audit',
      'pk5': '3,000 keyword Reserch/projects',
    },
    {
      'id': 3,
      'title': 'Diamond',
      'pk1': '10 Project',
      'pk2': '300 Chrome Extention Search limits',
      'pk3': '150 Tracked Keywords/Projects',
      'pk4': '5,000 page crawled/Site Audit',
      'pk5': '5,000 keyword Reserch/projects',
    },
    {
      'id': 4,
      'title': 'Enterprice',
      'pk1': '15 Project',
      'pk2': '900 Chrome Extention Search limits',
      'pk3': '200 Tracked Keywords/Projects',
      'pk4': '10,000 page crawled/Site Audit',
      'pk5': '10,000 keyword Reserch/projects',
    },
  ];
  return (
    <div className="planTable py-5 d-md-block d-lg-none">
      <div style={{ textAlign: 'center', marginBottom: '50px' }}>
        <h1>Compare pricing Plan</h1>
      </div>
      <Container>
        <div className="d-flex justify-content-center align-items-center">
          <div className="plan-grid">
            {
              descriptions.map((des) => <PlanPricingCard key={des.id} des={des} />)
            }
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PlanProps;
