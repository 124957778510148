import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from 'assets/images-v0.1.2/logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import pathname from 'routes';
import './NavigationBar.css';
import { redirect as commonRedirect } from 'helper';
import ClearIcon from '@mui/icons-material/Clear';
import drop1 from 'assets/images-v0.1.2/drop1.svg';
import drop2 from 'assets/images-v0.1.2/drop2.svg';
import drop3 from 'assets/images-v0.1.2/drop3.svg';
import drop4 from 'assets/images-v0.1.2/drop4.svg';
import arrow from 'assets/images-v0.1.2/arrow.svg';
import homeIcon from '../../assets/images/home_icon.svg';
import aboutIcon from '../../assets/images/about_icon.svg';
import serviceIcon from '../../assets/images/service_icon.svg';
import planPriceIcon from '../../assets/images/plan_price_icon.svg';
import contactUsIcon from '../../assets/images/contact_us_icon.svg';
import freeAuditIcon from '../../assets/images/free_audit_icon.svg';
import registerIcon from '../../assets/images/register_icon.svg';

function NavigationBar() {
  const [scroll, setScroll] = useState(false);
  const [show, setShow] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const closeShowNav = () => {
    setTimeout(() => {
      setShowNav(false);
    }, 250);
  };
  const navigate = useNavigate();
  const redirect = (navigate, path) => {
    setShowNav(false);
    commonRedirect(navigate, path);
  };
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
  }, []);
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
  }, [location.pathname]);

  const itemsOfNavbar = (
    <>
      <div className="navbar--items">
        <img src={homeIcon} alt="" />
        <Link
          className={`${location.pathname === pathname.home ? 'active' : ''} nav-link`}
          onClick={closeShowNav}
          to={pathname.home}
        >
          Home
        </Link>
      </div>
      <div className="navbar--items">
        <img src={aboutIcon} alt="" />
        <Link
          className={`${location.pathname === pathname.about ? 'active' : ''
          } nav-link`}
          onClick={closeShowNav}
          to={pathname.about}
        >
          About
        </Link>
      </div>
      <div className="navbar--items">
        <img src={serviceIcon} alt="" />
        <Link
          className={`${location.pathname === pathname.services
            || location.pathname === pathname.webServices
            || location.pathname === pathname.appService
            || location.pathname === pathname.productService
            || location.pathname === pathname.digitalService
            ? 'active'
            : ''
          } nav-link services-d`}
          onClick={closeShowNav}
          to={pathname.services}
          onMouseOver={() => setShow(true)}
        >
          Services
        </Link>
      </div>
      <div className="navbar--items">
        {process.env.REACT_APP_IS_PLAN_AND_PRICE === 'TRUE' && (
          <>
            <img src={planPriceIcon} alt="" />
            <Link
              className={`${location.pathname === pathname.planPrice ? 'active' : ''
              } nav-link`}
              onClick={closeShowNav}
              to={pathname.planPrice}
            >
              Plan & Price
            </Link>
          </>
        )}
      </div>
      <div className="navbar--items">
        <img src={contactUsIcon} alt="" />
        <div
          className={`${location.pathname === pathname.contact ? 'active' : ''
          } nav-link`}
          onClick={() => {
            // scroll to element with id contact
            const element = document.getElementById('calendly');
            element.scrollIntoView({ behavior: 'smooth' });
            closeShowNav();
          }}
          // to={pathname.contact}
        >
          Contact Us
        </div>
      </div>

      <div className="navbar--items">
        {process.env.REACT_APP_IS_AUDIT === 'TRUE' && (
          <>
            <img src={freeAuditIcon} alt="" />
            <Link
              className={`${location.pathname === pathname.freeAudit ? 'active' : ''
              } nav-link`}
              onClick={closeShowNav}
              to={pathname.freeAudit}
            >
              Free Audit
            </Link>
          </>
        )}
      </div>
      <div className="navbar--items">
        {process.env.REACT_APP_IS_REGISTER === 'TRUE' && (
          <>
            <img src={registerIcon} alt="" />
            <Link
              className={`${location.pathname === pathname.signup ? 'active' : ''
              } nav-link`}
              onClick={closeShowNav}
              to={pathname.signup}
            >
              Register
            </Link>
          </>
        )}
      </div>
      <div className="navbar--items">
        <img src={registerIcon} alt="" />

        <a
          className={`${location.pathname === pathname.contact ? 'active' : ''
          } nav-link`}
          onClick={closeShowNav}
          href="https://admin.only4values.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'underline',
          }}
        >

          Official Dashboard
        </a>
      </div>
    </>
  );
  return (
    <div onMouseLeave={() => setShow(false)}>
      <Navbar className={scroll ? 'scroll-shadow' : ''} fixed="top" expand="lg">
        <Container className="navigate">
          <Link className="navbar-brand" to={pathname.home}>
            <div>
              <img src={logo} alt="only4values" />
            </div>
          </Link>
          <Navbar.Toggle
            onClick={() => setShowNav(true)}
            aria-controls="basic-navbar-nav"
          />
          <Navbar id="basic-navbar-nav">
            <Nav className="me-auto text-center">{itemsOfNavbar}</Nav>
          </Navbar>
        </Container>
      </Navbar>
      {showNav ? (
        <div className="unique-nav">
          <div onClick={() => setShowNav(false)} className="close">
            <ClearIcon />
          </div>
          <div className="mobile--items">{itemsOfNavbar}</div>
        </div>
      ) : (
        ''
      )}
      {show ? (
        <div className="dropd">
          <div className="custom-dropdown d-flex gap-5">
            <div
              className={`${location.pathname === pathname.webServices ? 'active' : ''
              }`}
              onClick={() => redirect(navigate, pathname.webServices)}
            >
              <div className="d-flex align-items-center gap-3">
                <img src={drop1} alt="" />
                <h3>website development</h3>
              </div>
              <div className="ms-5 ps-4">
                <p className="d-flex align-items-center gap-3">
                  Business Website
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  Landing pages
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  portfolio website
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  Ecommerce website
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  custom software website
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  Blog website
                  <img src={arrow} alt="" />
                </p>
              </div>
            </div>
            <div>
              <div
                className={`${location.pathname === pathname.appService ? 'active' : ''
                }`}
                onClick={() => redirect(navigate, pathname.appService)}

              >
                <div className="d-flex align-items-center gap-3">
                  <img src={drop2} alt="" />
                  <h3>Application development</h3>
                </div>
                <div className="ms-5 ps-4">
                  <p className="d-flex align-items-center gap-3">
                    Android App
                    <img src={arrow} alt="" />
                  </p>
                  <p className="d-flex align-items-center gap-3">
                    IOS App
                    <img src={arrow} alt="" />
                  </p>
                  <p className="d-flex align-items-center gap-3">
                    Hybrid App
                    <img src={arrow} alt="" />
                  </p>
                </div>
              </div>
              <div
                className={`${location.pathname === pathname.digitalService ? 'active' : ''
                }`}
                onClick={() => redirect(navigate, pathname.digitalService)}
              >
                <div className="d-flex align-items-center gap-3">
                  <img src={drop3} alt="" />
                  <h3>digital marketing</h3>
                </div>
                <div className="ms-5 ps-4">
                  <p className="d-flex align-items-center gap-3">
                    Business website
                    <img src={arrow} alt="" />
                  </p>
                  <p className="d-flex align-items-center gap-3">
                    Landing pages
                    <img src={arrow} alt="" />
                  </p>
                  <p className="d-flex align-items-center gap-3">
                    portfolio website
                    <img src={arrow} alt="" />
                  </p>
                </div>
              </div>
            </div>
            <div
              className={`${location.pathname === pathname.productService ? 'active' : ''
              }`}
              onClick={() => redirect(navigate, pathname.productService)}
            >
              <div className="d-flex align-items-center gap-3">
                <img src={drop4} alt="" />
                <h3>Product design</h3>
              </div>
              <div className="ms-5 ps-4">
                <p className="d-flex align-items-center gap-3">
                  UI/UX design
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  website design
                  <img src={arrow} alt="" />
                </p>
                <p className="d-flex align-items-center gap-3">
                  Graphic design
                  <img src={arrow} alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default NavigationBar;
