/* eslint-disable max-len */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import marketv1 from '../../assets/images/marketv1.webp';
import marketv2 from '../../assets/images/marketv2.webp';
import marketv3 from '../../assets/images/marketv3.webp';

function DigitalSection() {
  return (
    <div className="webSection py-5">
      <h2 className="fw-bold" style={{ textAlign: 'center', marginBottom: '40px' }}>
        Services
      </h2>
      <div>
        <h3 className="d-none d-lg-block" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Search Engine Optimazation
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={marketv1} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Search Engine Optimazation
              </h4>
              <p className="py-2">
                Our Search Engine Optimization (SEO) strategies and tactics will increase organic search visibility on Google, generating leads, sales, and revenue. We are Web Core Vitals and Page Speed Experts. Our team will keep you and your business updated on the newest, most effective SEO services and strategies to dominate your market. We will deliver you detailed benchmark reports with existing keyword rankings and historical, organic search traffic and help you boost your rankings. Our team is passionate about delivering the best range of Internet Marketing strategies to drive your company toward revenue-generating initiatives.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our SEO</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Responsive Website Design</li>
                    <li>Accelerated Mobile Pages</li>
                    <li>White Hat SEO Work</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Internal Linking Structure</li>
                    <li>Better Keyword Ranking</li>
                    <li>Complete Ownership</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Search Engine Marketing
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={marketv2} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Search Engine Marketing
              </h4>
              <p className="py-2">
                We can build a professional Website like Search Engine Marketing (SEO) that allows online users to locate diverse information on the internet, including news, blog posts, and brand offerings, and make informed decisions. Additionally, we serve as a powerful marketing platform for industry players to expand their online reach and increase brand awareness. As a business owner, our goal is to ensure search engine marketing can find your website for it to show up and rank high on pertinent search engine results pages.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our SEM</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Increase Brand Awareness</li>
                    <li>Create Geo-Targeted Search Ads</li>
                    <li>Reach Your Clients Instantly</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Increases Traffic to Your Website</li>
                    <li>Simple Management</li>
                    <li>Appear On Competitors’ Related Keywords </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Social Media Marketing
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={marketv3} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Social Media Marketing
              </h4>
              <p className="py-2">
                Our Social media marketing company plays an integral role in digital marketing strategy and helps you achieve your business goals. With us by being active on social media platforms like Facebook, Instagram, and Pinterest, you're making your business more approachable and accessible to a wider audience, while also maintaining a good social presence—a vital part of your digital success. Our major social platforms will give your brand the space you need to foster an online community, start real conversations, and build lasting relationships with your customers, followers, and prospects.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our SMM</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Run Targeted Campaigns</li>
                    <li>Participation</li>
                    <li>Reliability</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Build Communities</li>
                    <li>Avoid Spamming</li>
                    <li>The Company Offers Great Content</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default DigitalSection;
