/* eslint-disable max-len */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import appv1 from '../../assets/images-v0.1.2/app1.svg';
import appv2 from '../../assets/images-v0.1.2/app2.svg';
import appv3 from '../../assets/images-v0.1.2/app3.svg';

function AppSection() {
  return (
    <div className="webSection py-5">
      <h2 className="fw-bold" style={{ textAlign: 'center', marginBottom: '40px' }}>
        Services
      </h2>
      <div>
        <h3 className="d-none d-lg-block" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Android Application
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid p-3 w-100 mx-auto" src={appv1} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Android Application
              </h4>
              <p className="py-2">
                We are an android development company that guarantees scalability with expertise in everything related to what is android app development.  Our Android development services ensure that it is fully capable of supporting established companies and their SME counterparts. We ensure that your android mobile application development is glitch-free and has zero lags. Our android mobile development process is engineered to be security and performance-first. We perform a combination of manual and automated testing processes to ensure a future-ready android application development.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Android App</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Fast loading speed</li>
                    <li>Mobile payment</li>
                    <li>Google Maps</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Social media tools integration</li>
                    <li>A compact design</li>
                    <li>Ability to work in offline mode</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          IOS Application
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid p-3 w-100 mx-auto" src={appv2} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                IOS Application
              </h4>
              <p className="py-2">
                Our IOS Applications are known for their memorable and immersive designs. We align your brand with what your users expect from the Apple ecosystem. Our IOS software developers ensure that your applications are engineered to make you future-forward businesses. We make sure your ISO application is glitch-free and hack-proof by applying a mix of manual and AI-driven automated modes of testing. Our team of IOS app developers helps businesses decide whether iOS is a good fit for them and which tech stack best suits their needs.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our IOS App</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>IOS App developers</li>
                    <li>Additional Privacy Features</li>
                    <li>Restyled Weather and Notes App</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Using smart features</li>
                    <li>Redesigned app</li>
                    <li>Share Play In Face Time Calls And Spatial Audio</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Hybrid Application
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid p-3 w-100 mx-auto" src={appv3} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Hybrid Application
              </h4>
              <p className="py-2">
                We Offer exceptional Hybrid Application maintenance and support services to ensure the optimal performance of the apps even in peak traffic times. Our hybrid app development solutions are robust, high-performing, feature-packed, and built to be both scalable and secure, able to handle any business and IT needs. Our team of hybrid app developers includes qualified UX designers, visual designers, and software engineers with deep expertise in mobile web development technologies. We help brands understand the role digital can play in realizing strategic opportunities and solving real-world business problems.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Hybrid App</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Enhanced UX/UI</li>
                    <li>Offline Availability</li>
                    <li>Enhanced Performance</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Cost-effective</li>
                    <li>Wider Market Reach</li>
                    <li>Shorter Development Timeframe</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AppSection;
