import * as api from 'apis/request';
import { handleCatch, handleTry } from 'store/helper/index';
import * as actionConstants from 'store/constants/index';
import { dataCollector } from 'helper/collector';

export const signUp = (formData, setSnackbar, cb) => async (dispatch) => {
  try {
    const response = await api.requestSignUp(formData);
    const { user, tokens, message, code } = response;
    handleTry({
      dispatch,
      dispatchType: actionConstants.AUTH,
      dispatchPayload: { user, token: tokens?.access?.token },
      setSnackbar,
      message,
      cb,
      cbData: { uniqueId: user?._id },
      code
    });
  } catch (response) {
    handleCatch({ response, setSnackbar, cb, dispatch, dispatchType: actionConstants.AUTH_ERROR });
  }
};

export const signIn = (formData, setSnackbar, cb) => async (dispatch) => {
  try {
    const response = await api.requestSignIn(formData);
    const { message, user, tokens, code } = response;
    handleTry({
      dispatch,
      dispatchType: actionConstants.AUTH,
      dispatchPayload: { user, token: tokens?.access?.token },
      setSnackbar,
      message,
      cb,
      code
    });
  } catch (response) {
    handleCatch({ response, setSnackbar, cb, dispatch, dispatchType: actionConstants.AUTH_ERROR });
  }
};

export const signOut = (formData, setSnackbar, cb, history) => async (dispatch) => {
  await api.requestSignOut(formData);
  try {
    handleTry({
      dispatch,
      dispatchType: actionConstants.LOGOUT,
      dispatchPayload: { history },
      setSnackbar,
      message: 'Successfully Logged Out',
      cb,
      cbData: true
    });
  } catch ({ response }) {
    handleCatch({ response, setSnackbar, cb, dispatch, dispatchType: actionConstants.AUTH_ERROR });
  }
};

export const createActivityTrace = (formData, cb) => async (dispatch) => {
  try {
    const { siteVisitData } = formData;

    const geoip = await api.requestIpAddress();
    const device = await dataCollector();

    const ip = geoip?.IPv4 || device?.ip;
    const deviceData = { geoip, device, ip };

    const { data: activityTrace } = await api.requestCreateActivityTrace({ ip, deviceData, siteVisitData, domain: window.location.origin });
    dispatch({ type: actionConstants.CREATE_ACTIVITY_TRACE, payload: { deviceData, activityTrace, siteVisitData } });
  } catch (response) {
    console.log('error-on-device-details');
  }
};

export const updateActivityTrace = (formData, cb) => async (dispatch) => {
  console.log('triggered-update-site-visit-insights', { formData });
  try {
    api.requestUpdateActivityTrace(formData);
    dispatch({ type: actionConstants.UPDATE_ACTIVITY_TRACE, payload: { siteVisitData: formData.siteVisitData } });
  } catch (response) {
    console.log('error-on-update-site-visit-insights');
  }
};
