import React from 'react';
import './PrivacyPolicy.css';
import { Col, Row } from 'react-bootstrap';
import terms from '../../assets/images/termsconditions.png';

function PrivacyPolicy() {
  return (
    <div>
      <div className="container py-5 mb-5 about-section">
        <Row className="align-items-center">
          <Col md={12} lg={8}>
            <div className="about-banner">
              <h1>
                Privacy policy
              </h1>
              <p>
                Only4Values offers a wide range of services like
                {' '}
                <br />
                {' '}
                Web Design, Web Development, Mobile App
                {' '}
                <br />
                {' '}
                Development, IT Consultancy.
              </p>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <img className="about-img" src={terms} alt="privacy policy" />
          </Col>
        </Row>
      </div>
      <div className="container">
        <h2 className="text-center">
          <span className="custom-color">Your Privacy </span>
          At A Glance
        </h2>
        <h4 className="text-center">Personal Data & Cookies Policy</h4>
      </div>
      <div className="container terms p-4 my-5">
        <div className="terms-items">
          <h3 className="g-color">Who we are</h3>
          <p>
            Our website address is: https://only4values.com.
            We provide a wide range of services like Web Design, Web Development, Mobile App Development, IT Consultancy and we also sell digital products and courses.
          </p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">Refund Policy</h3>
          <p className="pb-0">
            We do not offer refunds for any of our services or products unless otherwise stated.
          </p>
          <p>
            However, if you have following reasons to request a refund, we will consider your request:
            <ul>
              <li> You have not received the service/product you paid for.</li>
              <li> You have received the service/product but it is not as described.</li>
              <li> You have received the service/product but it is not working as expected.</li>
              <li> Mentioned in proof of concept document for software development services and not delivered within the time frame.</li>
              <li> Mentioned in proof of concept document for software development services and not delivered as per the requirements.</li>
              <li> Mentioned in proof of concept document for software development services and not delivered as per the quality standards.</li>
            </ul>
          </p>
          <p>
            To request a refund, please drop us an email at support@only4values.com with your order number and the reason for your request or you can contact us via our contact form.
          </p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">Cancellation Policy</h3>
          <p> You can request a cancellation of your order anytime, if you are eligible for a refund as per our refund policy, we will refund your amount within 14 working days.</p>
          <p>To cancel your order, please drop us an email at support@only4values.com with your order number and the reason for your request or you can contact us via our contact form.</p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">What personal data we collect and why we collect it</h3>
          <p>
            When visitors leave comments on the site we collect the data shown
            in the comments form, and also the visitor’s IP address and browser
            user agent string to help spam detection.An anonymized string created
            from your email address (also called a hash) may be provided to the
            Gravatar service to see if you are using it. The Gravatar service
            privacy policy is available here: https://automattic.com/privacy/.
            After approval of your comment, your profile picture is visible to
            the public in the context of your comment.
          </p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">Media</h3>
          <p>
            If you upload images to the website,
            you should avoid uploading images with embedded
            location data (EXIF GPS) included. Visitors to the website
            can download and extract any location data from images on the website.
          </p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">Maintenance of Cookies</h3>
          <p>
            If you leave a comment on our site you may opt-in to saving your name,
            email address and website in cookies. These are for your convenience
            so that you do not have to fill in your details again when you leave
            another comment. These cookies will last for one year.
          </p>
          <p>
            If you have an account and you log in to this site,
            we will set a temporary cookie to determine if your browser accepts cookies.
            This cookie contains no personal data and is discarded when you close your
            browser.
          </p>
          <p>
            When you log in, we will also set up several cookies to save your login
            information and your screen display choices. Login cookies last for two days,
            and screen options cookies last for a year. If you select “Remember Me”, your
            login will persist for two weeks. If you log out of your account, the login
            cookies will be removed.
          </p>
          <p>
            If you edit or publish an article, an additional cookie will be saved in
            your browser. This cookie includes no personal data and simply indicates
            the post ID of the article you just edited. It expires after 1 day.
          </p>
        </div>
        <div className="terms-items">
          <h3 className="g-color">Embedded content from other websites</h3>
          <p className="pb-0">
            Articles on this site may include embedded content
            (e.g. videos, images, articles, etc.). Embedded content
            from other websites behaves in the exact same way as if the
            visitor has visited the other website.
          </p>
          <p className="pb-0">
            These websites may collect data about you, use cookies,
            embed additional third-party tracking, and monitor your
            interaction with that embedded content, including tracking
            your interaction with the embedded content if you have an account
            and are logged in to that website.
          </p>
        </div>

        <div className="terms-items">
          <h3 className="g-color">Questions</h3>
          <p className="pb-0">
            If you have any questions about this Privacy Policy, please contact us at: support@only4values.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
