import React from 'react';
import {
  tech1, tech2, tech3, tech4, tech5, tech6, tech7, tech8,
  ind1, ind2, ind3, ind4, ind5, ind6, ind7, ind8, ind9,
  vision1, vision2, vision3, aboutBackground, aboutHeader,
  reactNative, socketIo, tailwindcss, aws, next,
} from 'assets';

import PageTitle from 'components/PageTitle/PageTitle';

import OurTeam from './OurTeam';
import './styles.css';

function About() {
  const vissionData = [
    { id: 1,
      img: vision1,
      title: 'Innovative solutions',
      description: 'We always think from a different angle when working on a client problem statement and, as a result, give innovative solution', },
    { id: 2,
      img: vision2,
      title: 'Trustworthiness',
      description: 'Trust is the initial factor that creates the relationship between you and us. We always assure you that your trust will never be broken in us.', },
    { id: 3,
      img: vision3,
      title: 'Time Management',
      description: 'We are strict on time. What we commit, we maintain. Out priority is to deliver the solution over-promised period', },
  ];

  const industries = [
    { ind: 'Travel & Hospitality', logo: ind1 }, { ind: 'Fashion', logo: ind2 }, { ind: 'Advertising', logo: ind3 }, { ind: 'Education', logo: ind4 }, { ind: 'Media & Entertainment', logo: ind5 }, { ind: 'Consumer service', logo: ind6 }, { ind: 'Retail & FMCO', logo: ind7 }, { ind: 'Stafing', logo: ind8 }, { ind: 'E-commerce', logo: ind9 },
  ];
  const technologies = [
    { logo: tech1, }, { logo: tech2, }, { logo: tech3, }, { logo: tech4, }, { logo: tech5, }, { logo: tech6, }, { logo: tech7, }, { logo: tech8, }, { logo: aws, }, { logo: next, },
    // { logo: reactNative, },
    { logo: socketIo, }, { logo: tailwindcss, isTailwind: true }
  ];
  return (
    <div style={{ overflowX: 'hidden' }}>
      <PageTitle title="About - Only4Values" />

      {/* About Header Start */}
      <div className="container mx-auto about--header--mainmx-2 about d-flex flex-column flex-lg-row justify-content-evenly align-items-center gap-3">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
          }}
        >
          <h1 className="about-title">
            <span style={{ color: '#E8AC25' }}>
              Experience Premium Quality & Unmatched Expertise
            </span>
          </h1>
          <p className="about-description">
            Welcome to Only4Values, your go-to partner for custom software development and IT solutions.
            We are a team of experts who are passionate about turning your ideas into market-ready products. Our focus on providing premium quality services and unparalleled customer support has made us a leading provider in the industry. Whether you're a start-up or an established business, we're here to help you take your company to the next level.

          </p>
          <div className="about-img-cover">
            <img src={aboutHeader} alt="" className="about--header--image--2" />
          </div>

          <p className="about-description-2">
            Welcome to Only4Values, your go-to partner for custom software development and IT solutions.
            We are a team of experts who are passionate about turning your ideas into market-ready products. Our focus on providing premium quality services and unparalleled customer support has made us a leading provider in the industry. Whether you're a start-up or an established business, we're here to help you take your company to the next level.

          </p>

        </div>
        <img src={aboutHeader} alt="" className="about--header--image" />
      </div>
      {/* About Header Ends */}

      {/* About Body Start */}
      <div style={{ backgroundImage: `url(${aboutBackground})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'repeat-y' }}>
        {/* Vision Starts */}
        <div className="py-5 vision">
          <div className="container">
            <h1 style={{ textAlign: 'center', marginBottom: '50px' }}>
              <span style={{ color: '#E8AC25' }}>Our </span>
              Vision
            </h1>
            <div className="vission-flex">
              <div className="vission-grid">
                {
              vissionData.map(({ id, img, title, description }) => (
                <div className="vision-item ">
                  <img className="vision-img" src={img} alt="visionimg" />
                  <h3 className="vision-title">{title}</h3>
                  <p className="vision-des">{description}</p>
                </div>
              ))
            }
              </div>
            </div>
          </div>
        </div>
        {/* Vision Ends */}

        {/* Industries Starts */}
        <div className="container my-5">
          <div className="py-2">
            <h1 className="text-center">
              <span style={{ color: '#E8AC25' }}>Industries </span>
              we serve
            </h1>
            <p className="text-center">We develop inovative business solution for business to help them boots their sales and profits</p>
          </div>
          <div className="">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              {industries.map((x) => (
                <div className="col">
                  <div className="d-flex align-items-center m-3">
                    <img width="50px" height="50px" src={x.logo} alt="" />
                    <p className="ms-3">{x.ind}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Industries Ends */}

        {/* Team Starts */}
        {/* <div className="container py-5">
          <h1 style={{ textAlign: 'center', marginBottom: '50px' }}>
            <span style={{ color: '#E8AC25' }}>Our </span>
            Team
          </h1>
          <OurTeam />
        </div> */}

        {/* Team Ends */}

        {/* Tech Expertise Starts */}
        <div className="container my-5 pb-5">
          <div className="">
            <h1 className="text-center my-5">
              <span style={{ color: '#E8AC25' }}>Our Capabilities and </span>
              Technological Expertise
            </h1>
          </div>
          <div className="">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gy-3 gx-4">
              {technologies.map((x) => (
                <div className="col">
                  <div className={`card-background ${x.isTailwind ? 'custom-tailwind-cell' : ''}`}>
                    <img className="card-img" src={x.logo} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <Carousel /> */}
        </div>
        {/* Tech Expertise Ends */}
      </div>
      {/* About Body Ends */}
    </div>
  );
}

export default About;
