const pathname = {
  home: '/',
  about: '/about',
  services: '/services',
  webServices: '/web-services',
  productService: '/product-services',
  digitalService: '/digital-services',
  appService: '/app-services',
  contact: '/contact',
  freeAudit: '/freeAudit',
  signup: '/signup',
  login: '/login',
  planandprice: '/plan&price',
  planPrice: '/plan-price',
  comingsoon: '/comingsoon',
  oopsError: '/oopsError',
  resetPassword: '/reset-password',
  varification: '/varification',
  yourEmail: '/your-email',
  massageSubmition: '/massage-submition',
  auditReqSubmition: '/audit-submition',
  termsConditions: '/terms-conditions',
  privacyPolicy: '/privacy-policy',
  blogs: '/blogs',
  singleBlog: '/single-blog',
  notFound: '*',
};
export default pathname;
