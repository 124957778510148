import AppSection from 'components/AppSection/AppSection';
import ServicesBanner from 'components/ServicesBanner/ServicesBanner';
import React from 'react';

function AppServices() {
  return (
    <>
      <ServicesBanner
        title="App Development"
        subTitle="Services"
        des="To create a successful mobile app for your business, you must hire a top mobile app development company in India. A great mobile app has a lot of potentials to bring in more customers and reach a wide range of people. "
      />
      <AppSection />
    </>
  );
}

export default AppServices;
