import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';

export function TextInput({ className, type, ...props }) {
  const [isPassword, setPassword] = useState(type === 'password');
  return (
    <>
      {/* {type === 'password' && <button onClick={() => setPassword(!isPassword)}>{isPassword ? 'Show' : 'Hide'}</button>} */}
      <input className={`${className}`} type={`${isPassword ? 'password' : 'text'}`} {...props} />
    </>
  );
}
export function NumberInput({ className, type, onChange, ...props }) {
  return (
    <>
      <input
        className={`${className}`}
        type="text"
        {...props}
        onChange={(evt) => {
          console.log('evt', evt.target.value);
          const { value } = evt.target;
          onChange({ target: { name: props.name, value: value ? value.replace(/\D/g, '') : '' } });
        }} // onChange only send number value...
      />
    </>
  );
}
export function SelectInput({ className, children, options, customOptions, ...props }) {
  return (

    <>
      <select className={`${className}`} {...props}>
        {children || options?.map((option, idx) => <option key={idx} value={option.value}>{option.name}</option>)}
        {Array.isArray(customOptions) && customOptions?.map((option, idx) => <option key={idx} value={option.value}>{option.name}</option>)}
      </select>
    </>
  );
}

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => (
  <ValueContainer {...props}>
    <Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </Placeholder>
    {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
  </ValueContainer>
);

export const CountrySelector = ({ name, onChange, value }) => {
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    onChange({ target: { name, value } });
  };
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      border: 'none',
      padding: '5px 10px',
      background: '#f5f5f5',
      margin: '10px 0'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: 'visible',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: state.hasValue || state.selectProps.inputValue ? 'none' : 'block',
      // position: 'absolute',
      // top: state.hasValue || state.selectProps.inputValue ? -25 : '15%',
      // transition: 'top 0.1s, font-size 0.1s',
      // fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected || state.isFocused ? 'white' : '#e6a428',
      background: state.isSelected || state.isFocused ? '#e6a428' : 'white',
      '&:hover': {
        color: state.isSelected || state.isFocused ? 'white' : '#e6a428',
        background: state.isSelected || state.isFocused ? '#e6a428' : 'white',
      }
    }),
    control: () => ({
      display: 'flex',
      color: '#e6a428',
      // none of react-select's styles are passed to <Control />
    }),
  };

  return (
    <Select
      placeholder="Country"
      styles={customStyles}
      components={{
        ValueContainer: CustomValueContainer
      }}
      options={options}
      value={value}
      onChange={changeHandler}
    />
  );
};
