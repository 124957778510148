const endpoints = {
  'baseUri': 'https://api.only4values.com',
  // 'baseUri': 'http://localhost:8000',
  'signIn': '/auth/login',
  'signUp': '/auth/register',
  'contact': '/contacts',
  'feedback': '/form/feedback',
  'support': '/form/support',
  'freeAudit': '/free-audit',
  'backendVersion': '/app-version',
  'activityTrace': '/insights',
};
export default endpoints;
