import React from 'react';
import smiley from '../../assets/images/smileface.png';
import './Submitted.css';

function Submitted({ title }) {
  return (
    <div className="container text-center py-5 mb-1">
      <h1 className="submit fw-bold">
        {title}
        {' '}
        is Successfully Submitted
      </h1>
      <p className="my-5">we will get back to you soon after reviewing your massage</p>
      <div className="submit-div">
        <h1 className="submit fw-bold">Thank you</h1>
        <img src={smiley} alt="smiley" />
      </div>
    </div>
  );
}

export default Submitted;
