/* eslint-disable max-len */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import productv1 from '../../assets/images/productv1.webp';
import productv2 from '../../assets/images/productv2.webp';
import productv3 from '../../assets/images/productv3.webp';

function ProductSection() {
  return (
    <div className="webSection py-5">
      <h2 className="fw-bold" style={{ textAlign: 'center', marginBottom: '40px' }}>
        Services
      </h2>
      <div>
        <h3 className="d-none d-lg-block" style={{ textAlign: 'center', marginBottom: '30px' }}>
          UI/UX Design
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={productv1} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                UI/UX Design
              </h4>
              <p className="py-2">
                We understand the importance of good design in digital success. We offer comprehensive UI/UX services that include branding, responsive web design, mobile app design, user experience consulting, and promotional designs using the latest tools and technologies. Specially, We are creating beautiful and smooth UI/UX designs that provide a better user experience by incorporating effective collaboration, and streamlined projects which strive for better results. We help clients thrive by simplifying designs that can make a lasting impact on the mind of the users which in turn, increase brand awareness and loyalty.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our UI/UX Design</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Creativity and Familiarity</li>
                    <li>Intuitive and Consistent</li>
                    <li>Always Consider the Human Element</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Maintainability</li>
                    <li>Make the product Simple and Joyful</li>
                    <li>Make the experience Valuable and Dynamic</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Web Design
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={productv2} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Web Design
              </h4>
              <p className="py-2">
                We create innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals with a web design agency, an award-winning design team. In today’s digital world, our website design is the first interaction consumers have with your business. We brand your site to your customized specification & offer our expertise whilst doing so. We ensure that not only is your site fully compatible across multiple & mobile devices but also dependable, seamless to run, and offers an immersive experience.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Web Design</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Easy navigation</li>
                    <li>Product Visuals and Descriptions</li>
                    <li>Social media as an extension of the business website</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Company Blog</li>
                    <li>Authoritative Website Content</li>
                    <li>Trendy, Intuitive design and user experience</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Graphic Design
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={productv3} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Graphic Design
              </h4>
              <p className="py-2">
                Our Graphic design services assist clients with the ideation and creation of outward-facing materials to help capture a company’s vision and draw the attention of potential and current customers. We can include packaging and merchandise design, signage, art installations, published materials, logos, and general art direction. We help enterprises increase productivity, and efficiency and save time and cost at the same time without compromising on quality. We have vast experience in developing highly customized websites for diverse types of businesses for a variety of clients globally.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Graphic Design</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Adobe Creative Cloud</li>
                    <li>Annotations</li>
                    <li>Image Database</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Collaboration Tools</li>
                    <li>Templates</li>
                    <li>Intuitive Interface</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ProductSection;
