export const AUTH = 'AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const LOGOUT = 'LOGOUT';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const UPDATE_ACTIVITY_TRACE = 'UPDATE_ACTIVITY_TRACE';
export const SITE_VISIT_INSIGHTS = 'SITE_VISIT_INSIGHTS';
export const UPDATE_ACTIVITY_TRACE_ERROR = 'UPDATE_ACTIVITY_TRACE_ERROR';
export const CREATE_ACTIVITY_TRACE = 'CREATE_ACTIVITY_TRACE';
