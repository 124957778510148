import React from 'react';
import programmerBlog from 'assets/images/programmerblog.webp';
import mobileBlog from 'assets/images/mobileblog.webp';
import PopularBlogCards from './PopularBlogCards';

function SingleBlogBody() {
  const details = [
    {
      id: 1,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 ~ Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 2,
      img: programmerBlog,
      date: 'Fri, 24 Dec 2022 ~ Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 3,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 ~ Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 4,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 ~ Mobile Application',
      title: 'Custom Mobile App'
    },
  ];
  return (
    <div className="container my-5">
      <div className="row g-3">
        <div className="col-12 col-md-8">
          <div className="">
            <h3>ReactJs and React Native</h3>
            <p>Fri, Dec 24 2022, ~ Mobile Application</p>
            <img
              style={{ width: '' }}
              className="img-fluid rounded-3 w-100 mb-5 shadow-lg"
              src={programmerBlog}
              alt=""
            />
            <div className="mt-3">
              <p>React Js and React Native are two technologies that are very comparable. However, there are certain distinctions to be aware of before embarking on the development of one's first native app or website</p>
              <p>Today, we'll go over what ReactJs and React Native are , as well as the primary distinctions between the two</p>
              <p>To learn more, continue reading</p>
              <h4 className="fw-bold">What is React js and how does it works?</h4>
              <p>React Js is an open-source, component-based front-end development library. It's generally used to create single-page apps' user interfaces.</p>
              <p>JavaScript, on the other hand, is a text-based programming language that is used to create interactive,dynamic, and scalable web pages and web applications in web development. It is in charge of including interactive components such as movies, animations, graphics, and more on web pages</p>
              <h4 className="fw-bold">What is React Native, and how does it work?</h4>
              <p>Facebook produced React Native, which is an open-source mobile application framework.So, in a nutshell, it enables web developers to create effective mobile applications utilizing their existing Javascript skills</p>
              <p>React Native allows developers to use both their native platform and the React framework features to create apps for iOS, Web, UWP, macOS, Android, TvOS, and Android TV.</p>
              <h4 className="fw-bold">React Native's Benefits.</h4>
              <p>The following are some of the benefits of using React native:</p>
              <h5 className="fw-bold">1. It can be utilized on a variety of platforms.</h5>
              <p>One of the mostappealing features of React Native is that it only requires a single learning curve and can be created anywhere. Both Android and iOS devices are supported.</p>
              <h5 className="fw-bold">2. It is simple to create apps.</h5>
              <p>If you know JavaScript, you can quickly create native mobile apps.</p>
              <h5 className="fw-bold">3. Provides the best possible results.</h5>
              <p>With the support of native modules and control, React Native excels at boosting performance.</p>
              <h5 className="fw-bold">4. Development is more rapid.</h5>
              <p>Development takes less time than with other programming languages because of the pre-developed components available in the open-source library. </p>
              <h5 className="fw-bold">Conclusion</h5>
              <p>Choosing between React Native and React Js is a question that requires you to consider the benefits and drawbacks of both technologies in order to come up with the best solution for you</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="">
            <div>
              <h3>Popular Blog</h3>
              <div>
                {
                  details.map((detail) => <PopularBlogCards key={detail.id} detail={detail} />)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleBlogBody;
