import React from 'react';
import { Link } from 'react-router-dom';
import './ServiceCard.css';

function ServiceCard({ service, path }) {
  const { img, title } = service;
  return (
    <div className="col">
      <div className="serviceCard text-center">
        <img className="img-fluid" src={img} alt="service" />
        <div className="service-alround p-3">
          <h3>{title}</h3>
          <Link className="service-link" to={path}>view detail</Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
