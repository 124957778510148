import React from 'react';
import errorPic from 'assets/images/errorpic.png';
import { BsArrowLeft } from 'react-icons/bs';

function OppsError() {
  return (
    <div className="mb-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <h1 className="fw-bolder" style={{ marginBottom: '-180px', fontSize: '180px' }}>Oops!</h1>
        </div>
        <div>
          <img className="img-fluid rounded-3" src={errorPic} alt="" />
        </div>
        <div>
          <h4 className="my-3">some error occurred</h4>
        </div>
        <div>
          <button type="button" className="border-1 py-2 px-5 rounded-pill bg-white border-warning text-warning">
            <BsArrowLeft className="me-2" />
            GO Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default OppsError;
