import React from 'react';
import GetInTouch from 'components/GetInTouch/GetInTouch';
import contactus1 from 'assets/images/contact1.svg';
import './ContactUs.css';
import PageTitle from 'components/PageTitle/PageTitle';

function ContactUs() {
  return (
    <>
      <PageTitle title="Contact - Get in touch to discuss your idea" />
      <div className="container">
        <div className="contact--upper">
          <div className="text--part">
            <h1>
              Want to build
              <br />
              {' '}
              something as per your
              <br />
              {' '}
              wish?
            </h1>
            <div className="image--for--mobile">
              <img className="img-fluid rounded-3" src={contactus1} alt="" />
            </div>
            <p>
              Come to us, we will provide you an
              <br />
              innovative solution.
            </p>
          </div>
          <div className="image--for--pc">
            <img className="img-fluid rounded-3" src={contactus1} alt="" />
          </div>
        </div>
        <GetInTouch />
      </div>
    </>
  );
}

export default ContactUs;
