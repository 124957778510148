import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './WebSection.css';
import servicev1 from '../../assets/images/servicev1.webp';
import servicev2 from '../../assets/images/servicev2.webp';
import servicev3 from '../../assets/images/servicev3.webp';
import servicev4 from '../../assets/images/servicev4.webp';
import servicev5 from '../../assets/images/servicev5.webp';
import servicev6 from '../../assets/images/servicev6.webp';

function WebSection() {
  return (
    <div className="webSection py-5">
      <h2 className="fw-bold" style={{ textAlign: 'center', marginBottom: '40px' }}>
        Services
      </h2>
      <div>
        <h3 className="d-none d-lg-block" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Business Website
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev1} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Business Website
              </h4>
              <p className="py-2">
                We can build a professional website for your business at a very
                price point with a unique design. Our business website design services are
                customized exclusively for startups at affordable rates. We use the latest
                technologies, create attractive design layouts and develop business-oriented
                features to upscale user experience.  A well-built website for small businesses
                can convert visitors into buyers by creating a solid impression within their
                target audience. This in turn increases revenue and helps your business grow.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Business Website</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Home Page Design</li>
                    <li>Internal Web Pages</li>
                    <li>Responsive Solution</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Website Footer</li>
                    <li>SEO-friendly Content</li>
                    <li>Backend Admin</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Landing pages
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev2} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Landing pages
              </h4>
              <p className="py-2">
                We can build a professional landing page for your ads and marketing
                activities. A professional landing page can increase lead quality,
                drive sales, and boost revenue. Landing page development seems simple
                but it is a complex task that requires expertise in digital marketing.
                A landing page is an excellent source to get the best return on
                investment. It is one of the most effective ways to increase solid
                leads and improve business. With our landing page designer services,
                you get a turn-key solution to designing, launching, and improving
                your landing pages, which translates to more leads, sales, and
                revenue for your business.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Landing pages</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Headline (and Sub-Headline)</li>
                    <li>Attention Ratio</li>
                    <li>Social Proof</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Big Benefits</li>
                    <li>Proximity</li>
                    <li>Highlighting</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Portfolio Website
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev3} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Portfolio Website
              </h4>
              <p className="py-2">
                We are ranked among the renowned organizations that are engaged to provide
                the best quality Portfolio website design and development services. The
                offered service is executed by our highly qualified professionals using
                premium-grade machines and the latest technology. Our skilled professionals
                properly examine all the parameters associated with this service and carry
                out it in the best possible manner. We develop WordPress websites and eCommerce
                solutions while maintaining a focus on user experience, responsive design,
                creativity, and growth.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Portfolio Website</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Banner design, category images</li>
                    <li>WhatsApp integration in website</li>
                    <li>Relevant Social Media Accounts</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Testimonials</li>
                    <li>A FAQ Section</li>
                    <li>Guest Appearances</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Ecommerce Website
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev4} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Ecommerce Website
              </h4>
              <p className="py-2">
                Our E-commerce Website development services help create a premium e-commerce experience
                to win demanding modern consumers. We create product and services marketplaces based
                on different models of seller engagement, order processing, and revenue management.
                Following your business vision, we give certain independence to sellers but keep all
                the workflows transparent and manageable. These e-commerce website development
                services ensure that the online business functions perfectly and maintains
                compatibility across different platforms.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our E-commerce website</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>User-Friendly Navigation</li>
                    <li>Advanced security features.</li>
                    <li>Shopping Cart</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Integration with physical retail.</li>
                    <li>Order Tracking</li>
                    <li>Generous Return Policy</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5" style={{ textAlign: 'center', marginBottom: '30px' }}>
          Custom software Website
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev5} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Custom software Website
              </h4>
              <p className="py-2">
                We are delivering quality customized software solutions for the web and mobile.
                Our custom web development solutions accelkerate workflows, boost revenues,
                and optimize business operations from concept to code and development to
                deployment. We develop reliable, well-documented, and easy-to-consume APIs
                that enable flexible integrations and customization of existing software
                products. Our software integration engineers adopt new technologies and
                processes to overcome challenges relating to everything from architectural design to testing to execution.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Custome software Website</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Potential Expansion</li>
                    <li>Frequent Productivity Speed Bumps</li>
                    <li>Pros and Cons of Custom Development</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Migration</li>
                    <li>CS Developent process</li>
                    <li>Easy integration</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <h3 className="d-none d-lg-block mt-5 " style={{ textAlign: 'center', marginBottom: '30px' }}>
          Blog Website
        </h3>
        <div className="container p-4">
          <Row>
            <Col className="p-0" md={12} lg={6}>
              <img className="img-fluid" src={servicev6} alt="business" />
            </Col>
            <Col className="border-alround" md={12} lg={6}>
              <h4 className="fw-bold d-block d-lg-none" style={{ textAlign: 'center', margin: '18px' }}>
                Blog Website
              </h4>
              <p className="py-2">
                We can build a professional Blog Website that allows you to share news, updates,
                and distinctive offers allowing you to have direct contact with the customers.
                A customer can connect with the website by commenting on specifications and articles.
                We are being in a business Industry blogging act as a powerful tool as it provides
                you with an opportunity to announce the inauguration of new products, and promote
                the product and initiatives.
              </p>
              <h5 className="mt-4 fw-bold">Features of Our Blog website</h5>
              <Row>
                <Col>
                  <ul className="web-ul">
                    <li>Headlines Should Be Attention Grabbers</li>
                    <li>A Blog must always be Dynamic</li>
                    <li>Optimized contents</li>
                  </ul>
                </Col>
                <Col>
                  <ul className="web-ul">
                    <li>Support</li>
                    <li>Support</li>
                    <li>Must use Infographic</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default WebSection;
