import React, { useState } from 'react';
import mainBanner from 'assets/images-v0.1.2/main graphics.svg';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { Link } from 'react-router-dom';
import pathname from 'routes';

import Questions from 'components/Questions/Questions';
import PageTitle from 'components/PageTitle/PageTitle';
import {
  mark, light, meeting, circle, digital, product, website, app, who, blog1, blog2, blog3,
} from 'assets';

import './styles.css';
import { Snackbar, defaultSnackbar } from 'components/Snackbar';

const Card = ({ img, title, handleAlert }) => (
  <div
    className="rounded-3 whatdo-card text-start"
    onClick={() => {
      handleAlert('This feature is not available yet. Click on "View All Services" instead!', 'warning');
    }}
  >
    <img className="mb-3 what-we-do-img" src={img} alt="digital icon" />
    <h3 className="mb-3 what-do-title">{title}</h3>
    <p
      className="whatdo-more d-flex align-items-center gap-1"
    >
      view More detail
      <EastOutlinedIcon style={{ width: '18px' }} />
    </p>
  </div>
);

function Home() {
  const [snackbar, setSnackbar] = useState(defaultSnackbar);
  const handleAlert = (message, type = 'warning') => {
    setSnackbar({
      isVisible: true,
      type,
      message: message || 'Something went wrong',
    });
  };

  return (
    <div>
      <PageTitle title="Software Development Company - Only4Values" />
      {/* Home Header Starts */}
      <div className="container pt-3">
        <div className="home-header gap-3">
          <div className="home-banner">
            <div className="center">
              <h1 className="home-title">
                Bring Your Crazy Ideas to Life

              </h1>
            </div>
            <div className="d-inline-block mb-4">
              <img src={mainBanner} alt="" />
            </div>
            <p className="pb-4 home-des">
              At only4values, we specialize in turning your unique ideas into real, market-ready software. Our team of highly skilled professionals is dedicated to delivering exceptional results and the highest level of service.

            </p>
            <div className="home-button">
              <div
                className="px-4 py-2 text-white fs-6 fw-bold btn plan-bg d-flex gap-2 align-items-center btn w-50 text-decoration-none justify-content-center"
                // to={pathname.contact}
                onClick={() => {
                  // scroll to element with id contact
                  const element = document.getElementById('calendly');
                  element.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get in Touch
                <EastOutlinedIcon />
              </div>
            </div>
          </div>
          <div>
            <img className="lg-img-home" src={mainBanner} alt="" />
          </div>
        </div>
      </div>
      {/* Home Header Ends */}

      {/* Who We Are Starts */}
      <div className="container py-5">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-5">
          <div>
            <img className="whoWe-img" src={who} alt="who" />
          </div>
          <div>
            <h1 className="who-title">
              <span style={{ color: '#E7AA27' }}>
                Ready to Bring Your Idea to Market?
              </span>
              {' '}

            </h1>
            <h1 className="who-img-cover">
              <img className="who-img" src={who} alt="whoImage" />
            </h1>
            <div className="who-we-des">
              <p style={{ color: 'rgba(0, 0, 0, 0.8)', fontSize: '18px', marginBottom: '30px' }}>If you're ready to turn your idea into a reality and want to work with a team that is committed to your success, we encourage you to get in touch with us. We look forward to discussing your project in more detail and showing you how we can support you every step of the way. </p>
              <p style={{ color: 'rgba(0, 0, 0, 0.8)', fontSize: '18px' }}>We are a team of highly skilled professionals who are dedicated to delivering exceptional results and the highest level of service. We specialize in turning your unique ideas into real, market-ready software. </p>
            </div>
          </div>
        </div>
      </div>
      {/* Who We Are Ends */}

      {/* Speak Count Starts */}
      <div className="container py-5">
        <div className="speak-counter">
          <div className="speak">

            <h1 className="count-title mt-3 mb-0"> Well, if you want any of these, </h1>
            <h1 className="count-title mb-3">you are at the right place.  </h1>
            <div className="text-white py-2 line-height">
              <p className="count-p">Want to automate stuff with some script or program?</p>
              <p className="count-p">Want to build a website or mobile app?</p>
              <p className="count-p">Want to build some custom software?</p>
            </div>

          </div>
          <div style={{ width: '45%' }}>
            <img className="circle-img" src={circle} alt="circle design" />
          </div>
        </div>
      </div>
      {/* Speak Count Ends */}

      {/*  What We Do Starts */}
      <div className="container py-5">
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between gap-5">
          <div>
            <h1 className="what-title">
              <span style={{ color: '#E7AA27' }}>Premium </span>
              {' '}
              Experience
            </h1>
            <div className="what-description">
              <p style={{ color: '#000000', fontSize: '18px', marginBottom: '30px', fontWeight: '600' }}>To ensure that our clients receive the best possible experience working with us, we only accept a limited number of clients at any given time. This allows us to provide personalized attention and ensure that each project receives the time and care it deserves.</p>

            </div>
            <div className="mt-5 pt-3 view-all">
              <div className="home-button">
                <div
                  className="px-4 py-2 text-white fs-6 fw-bold btn plan-bg d-flex gap-2 align-items-center btn w-50 text-decoration-none justify-content-center"
                  onClick={() => {
                    // scroll to element with id contact
                    const element = document.getElementById('calendly');
                    element.scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Get in Touch
                  <EastOutlinedIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <img className="meeting-img" src={meeting} alt="business Meeting" />
          </div>
        </div>
      </div>
      {/*  What We Do Ends */}

      {/* Follow Us Starts */}
      <div className="container py-5 mb-4">
        <h1 style={{ textAlign: 'center', marginBottom: '60px', color: '#3F3D56', fontWeight: '600' }}>
          <span style={{ color: '#E7AA27' }}>Process</span>
          {' '}
          we follow
        </h1>
        <div className="follow-content d-flex flex-column flex-lg-row align-items-center justify-content-between">
          <div className="follow-img">
            <img src={light} alt="light" />
          </div>
          <div className="text-dark follow-el">
            <div className="d-flex align-items-center gap-3 mb-4">
              <img src={mark} alt="mark" />
              <h6 className="follow-list">Gathering business requirment & Analysis</h6>
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <img src={mark} alt="mark" />
              <h6 className="follow-list">The draft design is prepared for the software.</h6>
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <img src={mark} alt="mark" />
              <h6 className="follow-list">Development of perfect codes by the developers.</h6>
            </div>
            <div className="d-flex align-items-center gap-3 mb-4">
              <img src={mark} alt="mark" />
              <h6 className="follow-list">All variants of the functional testing and non-functional testing are done.</h6>
            </div>
            <div className="d-flex align-items-center gap-3">
              <img src={mark} alt="mark" />
              <h6 className="follow-list">The finalized code is implemented into the software and then deployed or delivered to the customers to be used.</h6>
            </div>
          </div>
        </div>
      </div>
      {/* Follow Us Ends */}
      {/* Develop Starts */}
      <div className="container py-5">
        <h1 style={{ textAlign: 'center', marginBottom: '50px', color: '#3F3D56', fontWeight: '600' }}>
          <span style={{ color: '#E7AA27' }}>We Develop product </span>
          {' '}
          That people love to use
        </h1>
        <div className="d-flex justify-content-center">
          <div className="row" style={{ gap: '70px' }}>
            <div className="col">
              <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                <Card img={digital} title="Automations Development" handleAlert={handleAlert} />
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                <Card img={product} title="Custom Software Development" handleAlert={handleAlert} />
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                <Card img={website} title="Website Development" handleAlert={handleAlert} />
              </div>
            </div>
            <div className="col">
              <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                <Card img={app} title="Android & IOS Development" handleAlert={handleAlert} />
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link
            to={pathname.services}
            className="px-5 py-3 text-white fs-6 fw-bold btn plan-bg text-center mt-5"
          >
            View All Services
          </Link>
        </div>
      </div>
      {/* Develop Ends */}

      {/* Review Starts */}
      <div className="container py-5 text-white">
        <div className="pt-1 pb-3" style={{ backgroundColor: '#2F2D48', borderRadius: '1rem' }}>
          <h1 className="review-title-home pt-4" style={{ textAlign: 'center', color: '#ffffff', fontWeight: '600' }}>

            The amazing benefits you can expect.
          </h1>
          <div className="d-flex justify-content-between align-items-center review-items pb-2">
            <div>
              <h1 className="display-1 pb-2" style={{ fontWeight: '600' }}>,,</h1>
              <p className="fs-6">
                <b>
                  Personalized Attention:
                </b>
                {' '}
                We only take on a limited number of clients at a time, which allows us to provide personalized attention and support to each of our clients. If you need us, we're here for you.
              </p>
            </div>
            <div className="ms-5 d-none d-lg-block" style={{ width: '1px', height: '144px', background: '#D9D9D9', marginRight: '60px' }} />
            <div className="d-none d-lg-block">
              <h1 className="display-1 pb-2" style={{ fontWeight: '600' }}>,,</h1>
              <p className="fs-6">
                <b>
                  Strong Customer Support:
                </b>
                {' '}
                Our top priority is your satisfaction, which is why we're here to help you every step of the way. If you have any questions or concerns, just let us know. We're always here to support you.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Review Ends */}
      {/* Latest Blog Starts */}
      <div className="container py-5">
        <h1 style={{ textAlign: 'center', marginBottom: '40px', color: '#3F3D56', fontWeight: '600' }}>
          <span style={{ color: '#E7AA27' }}>Active</span>
      &nbsp;Projects
        </h1>
        <div className="latestblog__container mt-5">
          <div
            onClick={() => {
              handleAlert('At the moment We are not allowed to share the details of this project. Please contact us for more details. Thank you. ', 'error');
            }}
            className="blog1"
          >
            <img className="blog-img-1" src={blog1} alt="" />
            <div className="blog__text">
              <p className="text-dark date">Jan 2023 • Mobile Application</p>
              <h3 className="text-dark blog-title">Custom Team Management Mobile app</h3>
              <p className="blog-des">
                We are developing a custom team management mobile app.
                The app will be used by the employees to manage log tasks, time and attendance.
                It works in sync with jira tickets...
              </p>
              <p
                className="read-more"
              >
                Read more

              </p>
            </div>
          </div>
          <div
            onClick={() => {
              handleAlert('At the moment We are not allowed to share the details of this project. Please contact us for more details. Thank you. ', 'error');
            }}
            className="blog2"
          >
            <img className="blog-img-2" src={blog2} alt="" />
            <div className="blog__text">
              <p className="text-dark date">Oct 2022 • Scripts</p>
              <h3 className="text-dark blog-title">Scripts To Automate Tasks</h3>
              <p className="blog-des">
                We have created a number of scripts to automate tasks.
                These scripts are written in Nodejs and are used to automate tasks such as data collection, data processing, data analysis, and data visualization...
              </p>
              <p
                className="read-more"
              >
                Read more

              </p>
            </div>
          </div>
          <div
            onClick={() => {
              handleAlert('At the moment We are not allowed to share the details of this project. Please contact us for more details. Thank you. ', 'error');
            }}
            className="blog3"
          >
            <img className="blog-img-3" src={blog3} alt="" />
            <div className="blog__text">
              <p className="text-dark date">Aug 2022 • Dashboard</p>
              <h3 className="text-dark blog-title">SMM Dashboard</h3>
              <p className="blog-des">
                We are developing a custom dashboard for a social media management, and publishing audio, video, text, for social media platforms and blogs...
              </p>
              <p
                className="read-more"
              >
                Read more

              </p>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            handleAlert('This section is under construction. Please check back later. Thank you.', 'warning');
          }}
          className="latestblog__button"
        >
          View more custom projects
        </button>
      </div>
      {/* Latest Blog Ends */}

      {/* Questions Starts */}
      <Questions />
      {/* Questions Ends */}
      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => {
          setSnackbar(defaultSnackbar);
        }}
        type={snackbar?.type}
      />
    </div>
  );
}

export default Home;
