import React from 'react';
import Object from 'assets/images/404object.png';
import background from 'assets/images/404background.png';

function NotFoundPage() {
  return (
    <div
      className=""
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <h1 className="fw-bolder text-warning" style={{ fontSize: '150px' }}>
            404
          </h1>
        </div>
        <div>
          <h4 className="my-1">Page Not Found</h4>
        </div>
        <div>
          <img className="img-fluid rounded-3" src={Object} alt="" />
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
