import './App.css';
import Home from 'pages/Home/Home';
import {
  // BrowserRouter as Router,
  Routes, Route, useLocation,
} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import * as api from 'apis/request';

import NavigationBar from 'components/Navbar/NavigationBar';
import ContactUs from 'pages/ContactUs/ContactUs';
import About from 'pages/About/About';
import pathname from 'routes';
import Config from 'config';
import PlanAndPrice from 'pages/PlanAndPrice/PlanAndPrice';

import Services from 'pages/Services/Services';
import WebServices from 'pages/WebServices/WebServices';
import FreeAudit from 'pages/FreeAudit/FreeAudit';

import ProductServices from 'pages/ProductServices/ProductServices';
import DigitalServices from 'pages/DigitalServices/DigitalServices';
import ComingSoon from 'pages/ComingSoon/ComingSoon';
import OopsError from 'pages/OopsError/OopsError';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import PlanPrice from 'pages/PlanPrice/PlanPrice';
import AppServices from 'pages/AppServices/AppServices';
import { requestBackendVersion, requestIpAddress } from 'apis/request';
import { useEffect, useState } from 'react';
import * as actionConstants from 'store/constants/index';
import { useDispatch, useSelector } from 'react-redux';
import Submitted from 'pages/Submitted/Submitted';
import TermsConditions from 'pages/TermsConditions/TermsConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import Blogs from 'pages/Blog/Blogs';
import SingleBlog from 'pages/SingleBlog/SingleBlog';
import Footer from 'components/Footer/Footer';
import Login from 'pages/Login/Login';
import SignUp from 'pages/SignUp/SignUp';
import ResetPass from 'pages/ResetPass/ResetPass';
import VarificationCode from 'pages/VarificationCode/VarificationCode';
import YourEmail from 'pages/YourEmail/YourEmail';
import { createActivityTrace, updateActivityTrace } from 'store/actions/index.action';
import BrandAnimation from 'components/BrandAnimation/BrandAnimation';
import ScrollToTop from 'components/ScrollTop/ScrollTop';

function AppVersion() {
  const [backendVersion, setBackendVersion] = useState('0.0.0');

  useEffect(() => {
    requestBackendVersion().then((res) => (setBackendVersion(res.data.backendVersion)));
  }, []);

  // useGoogleOneTapLogin({
  //   onSuccess: (credentialResponse) => {
  //     const data = jwt_decode(credentialResponse.credential);
  //     console.log({ data });
  //   },
  //   onError: () => {
  //     console.log('Login Failed');
  //   },
  // });

  return (
    <div className="app-version">
      <span>
        {`O4V v${Config.version}`}
      </span>
      <span>
        {`Backend v${backendVersion}`}
      </span>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // site visit
  const [siteVisitAt] = useState(new Date().toISOString());

  // page visit logs data
  const [pageVisitData, setPageVisitData] = useState([]);

  // single page visit data
  const [pageName, setPageName] = useState('/');
  const [pageVisitAt, setPageVisitAt] = useState(new Date().toISOString());
  const authReducer = useSelector((state) => state.authReducer);
  const { activityTrace, deviceData } = authReducer;
  useEffect(() => {
    console.log({ authReducer });
  }, [authReducer]);

  // useEffect(() => {
  //   // if path changes scroll to top
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  const submitUnloadedTrace = () => {
    const payload = {
      siteVisitData: {
        siteVisitAt,
        siteLeaveAt: new Date().toISOString(),
        pageVisitData: [...pageVisitData,
          {
            pageName,
            pageVisitAt,
            pageLeaveAt: new Date().toISOString(),
          }]
      },
    };

    if (activityTrace?._id) {
      const { siteVisitData, } = payload;
      api.requestUpdateActivityTrace({ siteVisitData, id: activityTrace._id });
    }
  };

  useEffect(() => {
    window.addEventListener('load', setLoading(false));
    window.addEventListener('beforeunload', submitUnloadedTrace);

    // remove the event listener
    return () => {
      window.removeEventListener('load', setLoading(false));
      window.removeEventListener('beforeunload', submitUnloadedTrace);
    };
  }, []);

  useEffect(() => {
    // on page change

    // check if activityTrace data is available
    const newPageName = window.location.pathname;
    const newPageVisitAt = new Date().toISOString();

    // do nothing if the page is same
    if (window.location.pathname === pageName) return;

    // send updates
    const newPageVisitData = [...pageVisitData];
    newPageVisitData.push({
      pageName,
      pageVisitAt,
      pageLeaveAt: new Date().toISOString(),
    });

    setPageVisitData(newPageVisitData);

    setPageName(newPageName);
    setPageVisitAt(newPageVisitAt);
    const payload = {
      siteVisitData: {
        siteVisitAt,
        siteLeaveAt: 'Not Left Yet',
        pageVisitData: [
          ...newPageVisitData,
          {
            pageName: newPageName,
            pageVisitAt: newPageVisitAt,
            pageLeaveAt: 'Not Left Yet',
          }
        ]
      },
    };
    if (activityTrace?._id) {
      const { siteVisitData, } = payload;
      dispatch(updateActivityTrace({ siteVisitData, id: activityTrace._id }));
    } else dispatch(createActivityTrace(payload));
  }, [window.location.pathname, activityTrace]);

  const location = useLocation();

  return (
    <>
      { loading && <BrandAnimation /> }
      <AppVersion />
      {
        (location.pathname === pathname.login
          || location.pathname === pathname.signup
          || location.pathname === pathname.resetPassword
          || location.pathname === pathname.varification
          || location.pathname === pathname.yourEmail)
          ? null : <NavigationBar />
      }
      <div className="web-body">
        <ScrollToTop />
        <Routes>
          <Route exact path={pathname.home} element={<Home />} />
          <Route exact path={pathname.about} element={<About />} />
          <Route exact path={pathname.services} element={<Services />} />
          <Route exact path={pathname.webServices} element={<WebServices />} />
          <Route exact path={pathname.productService} element={<ProductServices />} />
          <Route exact path={pathname.digitalService} element={<DigitalServices />} />
          <Route exact path={pathname.appService} element={<AppServices />} />
          <Route exact path={pathname.blogs} element={<Blogs />} />
          <Route exact path={pathname.singleBlog} element={<SingleBlog />} />
          <Route exact path={pathname.contact} element={<ContactUs />} />
          <Route exact path={pathname.login} element={<Login />} />
          <Route exact path={pathname.signup} element={<SignUp />} />
          <Route exact path={pathname.resetPassword} element={<ResetPass />} />
          <Route exact path={pathname.varification} element={<VarificationCode />} />
          <Route exact path={pathname.yourEmail} element={<YourEmail />} />
          <Route exact path={pathname.freeAudit} element={<FreeAudit />} />
          <Route exact path={pathname.planandprice} element={<PlanAndPrice />} />
          <Route exact path={pathname.planPrice} element={<PlanPrice />} />
          <Route exact path={pathname.comingsoon} element={<ComingSoon />} />
          <Route exact path={pathname.oopsError} element={<OopsError />} />
          <Route exact path={pathname.massageSubmition} element={<Submitted title="Your Massage" />} />
          <Route exact path={pathname.auditReqSubmition} element={<Submitted title="Audit Request" />} />
          <Route exact path={pathname.termsConditions} element={<TermsConditions />} />
          <Route exact path={pathname.privacyPolicy} element={<PrivacyPolicy />} />
          <Route exact path={pathname.notFound} element={<NotFoundPage />} />
          <Route component={Home} />
        </Routes>
      </div>
      {
        (location.pathname === pathname.login
          || location.pathname === pathname.signup
          || location.pathname === pathname.resetPassword
          || location.pathname === pathname.varification
          || location.pathname === pathname.yourEmail)
          ? null : <Footer />
            }
    </>
  );
}

export default App;
