import React from 'react';
import blog from 'assets/images/blog.webp';

function SingleBlogHeader() {
  return (
    <div className="">
      <div className="container my-5 d-flex flex-column flex-md-row justify-content-evenly align-items-center gap-3">
        <div>
          <h1 style={{ marginLeft: '40px', fontSize: '50px' }}>
            {' '}
            Blogs
          </h1>
          <h3 style={{ fontSize: '40px' }}>
            ReactJS and React
            {' '}
            <br />
            {' '}
            Native
          </h3>
        </div>
        <div>
          <img
            className="img-fluid rounded-3"
            src={blog}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default SingleBlogHeader;
