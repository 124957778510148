import React from 'react';
import blog from 'assets/images/blog.webp';
import BlogCards from 'components/BlogCards/BlogCards';
import mobileBlog from 'assets/images/mobileblog.webp';
import programmerBlog from 'assets/images/programmerblog.webp';

function Blogs() {
  const details = [
    {
      id: 1,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 2,
      img: programmerBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 3,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 4,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 5,
      img: programmerBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 6,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 7,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 8,
      img: programmerBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 9,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 10,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 11,
      img: programmerBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
    {
      id: 12,
      img: mobileBlog,
      date: 'Fri, 24 Dec 2022 • Mobile Application',
      title: 'Custom Mobile App'
    },
  ];
  return (
    <div className="">
      <div className="container my-5 d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
        <div>
          <h1>
            Read our Lates
            {' '}
            <br />
            {' '}
            Blogs
          </h1>
          <p>
            Lorem ipsum dolor sit amet,
            consectetur adipiscing
            {' '}
            <br />
            {' '}
            elit.
            Lorem ipsum dolor sit amet, consectetur
            {' '}
            <br />
            adipiscing elit.
          </p>
        </div>
        <div>
          <img
            className="img-fluid"
            src={blog}
            alt="blog img"
          />
        </div>
      </div>
      <div className="container my-5 py-5">
        <div className="row gx-3 gy-5">
          {
            details.map((detail) => <BlogCards key={detail.id} detail={detail} />)
          }
        </div>
      </div>
    </div>
  );
}

export default Blogs;
