import ProductSection from 'components/ProductSection/ProductSection';
import ServicesBanner from 'components/ServicesBanner/ServicesBanner';
import React from 'react';

function ProductServices() {
  return (
    <>
      <ServicesBanner
        title="Product Design"
        subTitle="Services"
        des="Making a product design has never been easier than it is now. You can sign up for very cheap services that let you drag and drop a website for your business. But if you hire a professional Website design services, company to make your site for you, you can get many benefits that you wouldn't get if you did it yourself."
      />
      <ProductSection />
    </>
  );
}

export default ProductServices;
