export const dataCollector = async () => {
  const data = {
    timestamp: new Date(),
    browser: `${navigator.appName} ${navigator.appVersion}`,
    browserLanguage: navigator.language,
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    referrer: document.referrer || 'direct',
    userAgent: navigator.userAgent,
  };
  try {
    // get user IP
    const response = await fetch('https://api.ipify.org?format=json');
    const json = await response.json();
    data.ip = json.ip;
  } catch (error) {
    console.error('Error fetching user IP:', error);
  }
  const { userAgent } = navigator;
  data.os = 'unknown';
  if (userAgent.indexOf('Windows') !== -1) {
    data.os = 'Windows';
  } else if (userAgent.indexOf('Mac OS X') !== -1) {
    data.os = 'macOS';
  } else if (userAgent.indexOf('Linux') !== -1) {
    data.os = 'Linux';
  }

  return data;
};
