import React, { useState } from 'react';
import './PlanPricingCard.css';
import { BsCheck } from 'react-icons/bs';

function PlanPricingCard({ des }) {
  const [details, setDetails] = useState(false);
  return (
    <div className="planpriceCard">
      <div className="text-center p-3">
        <h1 className="g-color fs-3 fw-bold">{des.title}</h1>
        <h5 className="fw-bold fs-6 my-3">
          $12
          USD/
          month
        </h5>
        <p>
          loreum ipsum dolor
          {' '}
          <br />
          {' '}
          seamet aap
        </p>
        <button className="plan-btn">lets get started</button>
        <a className="priceLink" onClick={() => setDetails(!details)}>view More detail</a>
        {details && (
          <div>
            <span className="d-flex align-items-center">
              <BsCheck style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '15px', marginTop: '10px', marginLeft: '7px' }}>{des.pk1}</p>
            </span>
            <span className="d-flex align-items-center">
              <BsCheck style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '15px', marginTop: '10px', marginLeft: '7px' }}>{des.pk2}</p>
            </span>
            <span className="d-flex align-items-center">
              <BsCheck style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '15px', marginTop: '10px', marginLeft: '7px' }}>{des.pk3}</p>
            </span>
            <span className="d-flex align-items-center">
              <BsCheck style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '15px', marginTop: '10px', marginLeft: '7px' }}>{des.pk4}</p>
            </span>
            <span className="d-flex align-items-center">
              <BsCheck style={{ fontSize: '20px' }} />
              <p style={{ fontSize: '15px', marginTop: '10px', marginLeft: '7px' }}>{des.pk5}</p>
            </span>
          </div>
        ) }
      </div>
    </div>
  );
}

export default PlanPricingCard;
