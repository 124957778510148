import DigitalSection from 'components/DigitalSection/DigitalSection';
import ServicesBanner from 'components/ServicesBanner/ServicesBanner';
import React from 'react';

function DigitalServices() {
  return (
    <>
      <ServicesBanner
        title="Digital Marketing"
        subTitle="Services"
        des="Are you tired of not knowing how well your traditional marketing campaigns work? If so, it's time for your business to start investing money into digital marketing services that bring good results. When you use this tried-and-true online marketing strategy, your business will begin seeing the many benefits of digital marketing agency."
      />
      <DigitalSection />
    </>
  );
}

export default DigitalServices;
