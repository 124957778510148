import React from 'react';
import App from 'App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ComingSoonProduction } from 'pages/ComingSoon/ComingSoon';

import { Provider, useSelector } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from 'store/reducers/index.reducer';

import { GoogleOAuthProvider } from '@react-oauth/google';

const container = document.getElementById('root');
const root = createRoot(container);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers,
  composeEnhancers(applyMiddleware(thunk)));

const CheckComingSoon = () => {
  console.log('process.env.REACT_APP_IS_PRODUCTION', process.env.REACT_APP_IS_PRODUCTION, process.env.REACT_APP_IS_PRODUCTION === 'TRUE');
  // if (process.env.REACT_APP_IS_PRODUCTION === 'TRUE') { return (<ComingSoonProduction />); }
  return (
    <HelmetProvider>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="569561530580-d720omuqkll0d6st8uh2416e6dcgjoar.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  );
};

root.render(<BrowserRouter><CheckComingSoon /></BrowserRouter>);
