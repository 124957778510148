import ServicesBanner from 'components/ServicesBanner/ServicesBanner';
import WebSection from 'components/WebSection/WebSection';
import React from 'react';

function WebServices() {
  return (
    <>
      <ServicesBanner
        title="Web development"
        subTitle="Services"
        des="We provide a quality range of web development services that may help any business improve. Our web services encompass front-end and back-end development because of our status as one of the leading web development companies."
      />
      <WebSection />
    </>
  );
}

export default WebServices;
