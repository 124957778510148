import OurServices from 'pages/Services/OurServices/OurServices';
import PageTitle from 'components/PageTitle/PageTitle';
import ServicesBanner from 'components/ServicesBanner/ServicesBanner';
import React from 'react';

function Services() {
  return (
    <>
      <PageTitle title="Service - Best inovative services, we provide" />
      <ServicesBanner
        title="Best inovative services"
        subTitle="we provide"
        des="it's easy for anyone to make a website these days, if you want to build a website for your business, you need a professional website design."
      />
      <OurServices />
    </>
  );
}

export default Services;
