import React, { useEffect, useState } from 'react';
import contactus1 from 'assets/images/contactus1.png';
import PlanCard from 'components/PlanCard/PlanCard';

function PlanandPrice() {
  const [plan, setPlan] = useState([]);

  useEffect(() => {
    fetch('planandprice.json')
      .then((res) => res.json())
      .then((data) => setPlan(data));
  }, []);

  return (
    <div className="container ">
      <div className="my-5 d-flex flex-column flex-md-row justify-content-evenly align-items-center gap-3">
        <div>
          <h1> Get plan for your website </h1>
          <h1> development & design</h1>
          <p className="w-75 mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur
            dolore voluptatibus tenetur quaerat vitae, ea neque ex possimus
            suscipit dicta.
          </p>
        </div>
        <div>
          <img className="img-fluid rounded-3" src={contactus1} alt="" />
        </div>
      </div>
      <div className="my-5">
        <h1 className="text-center my-5">Compare pricing plan</h1>
        <div className="row g-4 ">
          {plan.map((data) => (
            <PlanCard key={data.index} data={data} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PlanandPrice;
