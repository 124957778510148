import SingleBlogBody from 'components/SingleBlogBody/SingleBlogBody';
import SingleBlogHeader from 'components/SingleBlogHeader/SingleBlogHeader';
import React from 'react';

function SingleBlog() {
  return (
    <div>
      <SingleBlogHeader />
      <SingleBlogBody />
    </div>
  );
}

export default SingleBlog;
