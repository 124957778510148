/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import Config from 'config';
import pathname from 'routes';
import { Link } from 'react-router-dom';
import footerCircle from '../../assets/images-v0.1.2/footerCircle.svg';
import logo from '../../assets/images-v0.1.2/logoF.svg';

const Footer = () => (
  <div>
    <div
      style={{
        backgroundColor: '#fff',
      }}
    >
      <h2
        style={{
          color: '#feb200',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          textAlign: 'center',
          textDecoration: 'underline',
          backgroundColor: '#FBFCFD',
          margin: '0',
          paddingTop: '2rem',
        }}
      >
        Book a Discussion, Let's Talk
      </h2>
      <iframe
        id="calendly"
        src="https://calendly.com/rahul-o4v/bring-your-idea-to-life"
        className="calendly--iframe"
      />
    </div>

    <div
      style={{
        backgroundColor: '#FBFCFD',
      }}
      className="container--yellow--box"
    >
      <div
        className="yellow--box"
      >
        <div className="get--in--touch">
          <h4>

            “Let's connect & discuss your ideas”

          </h4>
          <h4>
            I would be happy to help your idea be market ready software!!
          </h4>
          {/* <div
            className="get--in--touch--button"
            onClick={() => {
              // scroll to element with id contact
              const element = document.getElementById('calendly');
              element.scrollIntoView({ behavior: 'smooth' });
            }}
            // to={pathname.contact}
          >
            GET IN TOUCH

          </div> */}
        </div>
        <img src={footerCircle} alt="" />
      </div>
    </div>
    <div className="purple--box">
      <div className="logo--copyright">
        <img src={logo} alt="only4values" />
        {/* <p>        H - 47, Sarai Kale Khan, Delhi,India, 110013</p> */}
        <p>
          Copyright © 2022 Only4value. All rights reserved.
        </p>
      </div>
      <div className="social--links">
        <div><FontAwesomeIcon size="2x" icon={faLinkedin} /></div>
        <div><FontAwesomeIcon size="2x" icon={faTwitter} /></div>
        <div><FontAwesomeIcon size="2x" icon={faInstagramSquare} /></div>
        <div><FontAwesomeIcon size="2x" icon={faFacebookSquare} /></div>
      </div>
    </div>
  </div>
);

export default Footer;
