import React from 'react';
import { Col, Row } from 'react-bootstrap';
import serviceImg from '../../assets/images/servicesimg.png';
import './ServicesBanner.css';

function ServicesBanner({ title, subTitle, des }) {
  return (
    <div className="container service-section">
      <Row className="align-items-center">
        <Col md={12} lg={6}>
          <div className="service-banner">
            <h1 className="fw-bold">
              {title}
              {' '}
              <br />
              {' '}
              {subTitle}
            </h1>
            <p>
              {des}
            </p>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <img className="service-img" src={serviceImg} alt="service banner" />
        </Col>
      </Row>
    </div>
  );
}

export default ServicesBanner;
