const Config = {
  version: '2.1.2',
  socialLink: {
    linkedIn: 'https://www.linkedin.com/company/only4values',
    facebook: 'https://www.facebook.com/Only4values-109488501843874',
    instagram: 'https://www.instagram.com/only4values',
    twitter: 'https://twitter.com',
    youtube: 'https://youtube.com',
    telegram: 'https://telegram.org',
  }
};
export default Config;
