import React, { useEffect, useState } from 'react';
import { requestContact } from 'apis/request';
import './GetInTouch.css';
import { useNavigate } from 'react-router-dom';
import pathname from 'routes';
import { TextInput, NumberInput, CountrySelector } from 'components/Input';
import { Snackbar, defaultSnackbar } from 'components/Snackbar';
import phone from '../../assets/images/phone.svg';
import location_icon from '../../assets/images/location_icon.svg';
import email from '../../assets/images/email.svg';

const initialFormData = {
  fullName: '',
  email: '',
  phone: '',
  message: '',
  country: '',
};

const customStylesForSelector = {
  option: (provided, state) => ({
    ...provided,
    border: 'none',
  }),
};
function GetInTouch() {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(defaultSnackbar);

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    // if (!value) return setFormErrors({ ...formErrors, [name]: { error: true, message: 'Input is empty' } });
    const data = { ...formData };
    data[name] = value;
    setFormData(data);
  };
  // instead of console.log(formData) in useEffect, use react extension on chrome to see the formData

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const { code } = await requestContact(formData);

    setTimeout(() => {
      setLoading(false);
      if (code === 200) {
        setFormData(initialFormData);
        setSnackbar({
          isVisible: true,
          type: 'success',
          message: 'We recieved your message, will get back to you soon'
        });
      } else {
        setSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Failed to submit message',
        });
      }
    }, [1000]);
  };

  useEffect(() => {
    window.scrollTo(0, document.getElementById('contactForm').offsetTop);
  });

  return (
    <div className="py-5">
      <div className="main--box">
        <div className="contactus--info--box">
          <div className="contactus--box--one" />
          <div className="contactus--box--two" />
          <h4>
            <u>Get in Touch</u>
          </h4>
          <div>
            <div className="my-4 getintouch--sections">
              <img src={location_icon} className="fs-3 me-3" />
              <span>
                Infomation technologies building, Victoria Island, Lagos,
                Nigeria.
              </span>
            </div>
            <div className="my-4 getintouch--sections">
              <img src={phone} className="fs-3 me-3" />
              <span>+234 081-1236-4568</span>
            </div>
            <div className="my-4 getintouch--sections">
              <img src={email} className="fs-3 me-3" />
              <a className="text-white" href="mailto:info@only4values.com">
                info@only4values.com
              </a>
            </div>
          </div>
        </div>
        <div id="contactForm" className="contactus--message--box">
          <div className="contactus--white--box" />
          <div className="contactus--box--three" />
          <div className="contactus--box--four" />
          <h4>Leave us a message</h4>
          <form>
            <TextInput
              disabled={loading}
              label="Full Name"
              type="text"
              name="fullName"
              onChange={handleChange}
              value={formData.fullName}
              className="form-control"
              placeholder="Full Name"
            />
            <TextInput
              disabled={loading}
              onChange={handleChange}
              name="email"
              value={formData.email}
              type="email"
              className="form-control"
              placeholder="Email Address"
            />
            <div className="country--selector">
              <CountrySelector
                styles={customStylesForSelector}
                name="country"
                onChange={handleChange}
                value={formData.country}
              />
            </div>
            <NumberInput
              disabled={loading}
              onChange={handleChange}
              name="phone"
              value={formData.phone}
              type="text"
              maxLength={18}
              className="form-control"
              placeholder="Contact No"
            />
            <textarea
              disabled={loading}
              onChange={handleChange}
              name="message"
              className="form-control"
              rows="4"
              value={formData.message}
              placeholder="Your Message"
            />
            <div className="d-grid gap-2">
              <button
                onClick={handleSubmit}
                disabled={loading}
                className="text-white fw-bold btn contact--btn"
              >
                {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        isVisible={snackbar?.isVisible}
        message={snackbar?.message || ''}
        onClose={() => {
          setSnackbar(defaultSnackbar);
        }}
        type={snackbar?.type}
      />
    </div>
  );
}

export default GetInTouch;
