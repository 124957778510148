import PlanProps from 'components/PlanProps/PlanProps';
import PlanTable from 'components/PlanTable/PlanTable';
import React from 'react';
import frame from '../../assets/images/Frame.png';
import './PlanPrice.css';

function PlanPrice() {
  return (
    <>
      <div className="container my-5">
        <div className="py-5 d-flex flex-column flex-md-row justify-content-evenly align-items-center gap-3">
          <div>
            <h1 className="fw-bold"> Get plan for your website </h1>
            <h1 className="fw-bold"> development & design</h1>
            <p className="w-75 mt-4">
              Our web service developer is well aware of the importance of showcasing pricing information on our website in an easy-to-read and stylish manner.
            </p>
          </div>
          <div>
            <img className="img-fluid" style={{ marginTop: '-60px' }} src={frame} alt="" />
          </div>
        </div>
      </div>
      <PlanTable />
      <PlanProps />
    </>
  );
}

export default PlanPrice;
